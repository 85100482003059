import React, { FC, useRef, useState, useCallback } from 'react';

import { MonthRangePicker } from 'molecules/Calendar/MonthRangePicker';
import {
  Popper,
  IconButton,
  Tooltip,
  InputAdornment,
  ClickAwayListener,
} from '@mui/material';
import { StyledTextField } from 'molecules/Calendar/Calendar.styles';
import { IconTextFieldWrapper } from '../IconTextField/IconTextField.styles';
import { palette } from 'utils/styles/variables';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendarMonth.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import dayjs from 'dayjs';

const ButtonMonthRangePicker: FC<{
  tooltip: string;
  onChange: (v: [null | Date, null | Date]) => void;
}> = ({ tooltip, onChange }) => {
  const anchorElRef = useRef<null | HTMLElement>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [range, setRange] = useState<[null | Date, null | Date]>([null, null]);

  const isFilled = !!(range[0] && range[1]);

  const handleClickIcon = () => {
    setOpen((isOpen) => !isOpen);
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !isFilled));

    setRange([null, null]);
    onChange([null, null]);
  };

  const handleClickOutside = useCallback(() => {
    setOpen((isOpen) => (isOpen ? false : !isFilled));

    if (isFilled) onChange(range);
  }, [isFilled, range]);

  const handleChange = useCallback(
    ({
      startMonth,
      startYear,
      endMonth,
      endYear,
    }: {
      startMonth: number;
      startYear: number;
      endMonth: number;
      endYear: number;
    }) => {
      const value: [Date, Date] = [
        new Date(startYear, startMonth - 1, 1),
        new Date(endYear, endMonth - 1, 1),
      ];
      setRange(value);
    },
    []
  );

  const formattedStart = range[0]
    ? dayjs(dayjs(range[0])).format('MMM YYYY')
    : '';
  const formattedEnd = range[1]
    ? dayjs(dayjs(range[1])).format('MMM YYYY')
    : '';

  return (
    <IconTextFieldWrapper>
      <Tooltip title={tooltip || 'Filter by date'}>
        <IconButton
          aria-describedby="transition-popper"
          aria-label="close"
          onClick={handleClickIcon}
          sx={{
            p: '0 !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disabled={open}
        >
          <CalendarIcon color={palette.primary.main} />
        </IconButton>
      </Tooltip>

      <StyledTextField
        label=""
        size="small"
        variant="standard"
        inputRef={anchorElRef}
        onClick={() => setOpen((prev) => !prev)}
        sx={{
          width: 300,
          display: open || isFilled ? 'flex' : 'none',
          input: {
            p: '0 !important',
            textAlign: 'center',
          },
        }}
        inputProps={{
          placeholder: 'Select period',
          readOnly: true,
        }}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          endAdornment: isFilled ? (
            <InputAdornment position="end">
              <IconButton
                sx={{ padding: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleClick();
                }}
              >
                <CloseIcon width={16} height={16} />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
        value={
          formattedStart && formattedEnd
            ? `${formattedStart} - ${formattedEnd}`
            : ''
        }
      />

      <Popper
        id="transition-popper"
        open={open}
        anchorEl={anchorElRef.current}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClickOutside}>
          <div>
            <MonthRangePicker
              value={range}
              initialYear={2000}
              onRangeSelect={handleChange}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </IconTextFieldWrapper>
  );
};

export default ButtonMonthRangePicker;
