/* eslint-disable camelcase */
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  MenuItem,
  ClickAwayListener,
  Stack,
} from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { usd } from 'utils/helpers/currencyFormatter';
import { useNavigate } from 'react-router-dom';
import Block from 'molecules/Card';
import { ListItem, ListItemText, StyledTextField } from './PastDueCard.styles';

const sortByOptions = [
  'Highest outstanding balance',
  'Highest count of past due invoices',
  'Oldest past due invoices sorted by date',
];

interface ICompanyModel {
  items: {
    company: string;
    total: number;
    company_hs_id: number;
  }[];
}

const PastDueCard = () => {
  const boxRef = useRef(null);
  const navigate = useNavigate();

  const [sortBy, setSortBy] = useState('Highest outstanding balance');
  const [companies, setCompanies] = useState<
    {
      company_hs_id: number;
      company: string;
      total: number;
    }[]
  >();
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const onOpen = () => {
    setOpen((prev) => !prev);
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOpen(false);
    setSortBy(event.target.value);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    let x = '';

    if (sortBy === 'Highest outstanding balance') {
      x = 'highest_balance';
    } else if (sortBy === 'Highest count of past due invoices') {
      x = 'highest_count';
    } else if (sortBy === 'Oldest past due invoices sorted by date') {
      x = 'oldest_date';
    }

    axios
      .get<ICompanyModel>(
        `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/top-ten-past-due`,
        {
          params: {
            sort_by: x,
          },
        }
      )
      .then(({ data }) => setCompanies(data.items));
  }, [sortBy]);

  return (
    <Block
      title="Top 10 debtors"
      avatar={<CardAvatar />}
      sx={{
        position: 'relative',
        '.MuiPaper-root': {
          top: '117px !important',
          left: '16px !important',
        },
        minHeight: '340px',
      }}
    >
      <>
        <Box ref={boxRef} sx={{ overflow: 'initial !important' }}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledTextField
              sx={{ width: '100%' }}
              size="small"
              select
              label="Sort by"
              value={sortBy}
              onChange={onChange}
              focused={focused || open}
              SelectProps={{
                open,
                onOpen,
                onClose: () => {
                  setFocused(false);
                },
                onMouseEnter: () => {
                  setFocused(true);
                },
                onMouseLeave: () => {
                  setFocused(false);
                },
                MenuProps: {
                  container: boxRef.current,
                  sx: { position: 'unset' },
                  onBlur: () => {
                    setFocused(false);
                  },
                },
              }}
            >
              {sortByOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </StyledTextField>
          </ClickAwayListener>
        </Box>
        <Stack sx={{ my: '16px', gap: '8px' }}>
          {companies && companies.length > 0 ? (
            companies.map(
              (item) =>
                item.company !== '' && (
                  <ListItem key={item.company}>
                    <ListItemText
                      $cursor
                      onClick={() => navigate(`/company/${item.company_hs_id}`)}
                    >
                      {item.company}
                    </ListItemText>
                    <ListItemText>
                      {item.total ? usd.format(item.total) : '$0'}
                    </ListItemText>
                  </ListItem>
                )
            )
          ) : (
            <Box>
              <Typography variant="body1" fontSize="0.875rem">
                Data is empty
              </Typography>
            </Box>
          )}
        </Stack>
      </>
    </Block>
  );
};

export default PastDueCard;
