import { Box, Tooltip } from '@mui/material';
import axios from 'axios';

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { formatDate } from 'utils/helpers/dates';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIcon.svg';
import { ReactComponent as PasswordIcon } from 'assets/icons/PasswordIcon.svg';
import { ReactComponent as MainContactOutlinedIcon } from 'assets/icons/MainContactOutlined.svg';
import { validPassword } from 'utils/helpers/formValidators';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';

import { ICompanies, ICompany } from './types';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import CurrencySvgIcon from 'atoms/CurrencyIcon';
import ContactIcon from 'atoms/ContactIcon';
import { UserRow } from 'organisms/CompanyDetailsCard/CompanyContacts';
import Table from 'molecules/Table';
import {
  StyledCellContainer,
  StyledTableContainer,
} from 'molecules/Table/styles';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import Link from 'atoms/Link/Link';
import ButtonMenu from 'molecules/ButtonMenu';
import { neutrals } from 'utils/styles/color';
import { UserCompany } from 'store/user/userSlice';
import { palette } from 'utils/styles/variables';
import ChangePasswordModal from './components/ChangePasswordModal';
import PasswordChangedModal from './components/PasswordChangedModal';
import DeleteContactModal from './components/DeleteContactModal';
import { ReactComponent as LinkIcon } from 'assets/icons/broken-link.svg';

export const getContactDotsOptions = (isMain: boolean | null) => {
  const options = [
    {
      icon: <PasswordIcon color={neutrals[700]} />,
      label: 'Change password',
      value: 'password',
    },
    {
      icon: <DeleteIcon color={neutrals[700]} />,
      label: 'Delete contact',
      value: 'delete',
    },
  ];

  if (isMain === null) return options;

  if (!isMain) {
    return [
      {
        icon: <MainContactOutlinedIcon color={neutrals[700]} />,
        label: 'Designate as main contact',
        value: 'main',
      },
      ...options,
    ];
  }

  return options;
};

const Companies = ({
  order,
  orderBy,
  onRequestSort,
  companiesRow,
  totalCount,
  users,
  update,
  setUpdate,
  setLimit,
  setPage,
  page,
  loading,
}: ICompanies) => {
  const { value: user } = useBaoSelector((state) => state.user);

  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [rowsPerPage, setRowsPerPage] = useState<number>(20);
  const [, setRowsPage] = useState<number>(page || 0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deletingId, setDeletingId] = useState<ICompany>(companiesRow[0]);
  const [passModal, setPassModal] = useState<boolean>(false);
  const [sucModal, setSucModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [errorPass, setErrorPass] = useState<boolean>(false);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [showDeleteCaptcha, setShowDeleteCaptcha] = useState<boolean>(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setRowsPage(newPage);
    if (setPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (option: number) => {
    setRowsPerPage(option);
    if (setLimit) {
      setLimit(option);
    }
  };

  const showDeleteCaptchaDialog = useCallback(() => {
    setShowDeleteCaptcha(true);
    setDeleteDialog(false);
  }, []);

  const hideDeleteCaptcha = useCallback(() => {
    setShowDeleteCaptcha(false);
    setDeleteDialog(false);
  }, []);

  const deleteMethod = useCallback(() => {
    hideDeleteCaptcha();

    axios
      .delete(`${process.env.REACT_APP_USER_SERVICE}/delete/${deletingId.id}`)
      .then(() => {
        if (update && setUpdate) {
          const number = update + 1;
          setUpdate(number);
          setDeleteDialog(false);
        }
      });
  }, [update, setUpdate, hideDeleteCaptcha, deletingId]);

  const showCaptchaDialog = useCallback(() => {
    if (!password || !validPassword(password)) {
      setErrorPass(true);
      return;
    }

    setShowCaptcha(true);
    setPassModal(false);
  }, [password]);

  const hideCaptcha = useCallback(() => {
    setShowCaptcha(false);
    // setPassModal(true);
  }, []);

  const confirm = useCallback(() => {
    hideCaptcha();

    if (validPassword(password)) {
      axios
        .patch(`${process.env.REACT_APP_USER_SERVICE}/update-user-password`, {
          password,
          user_id: deletingId.id,
        })
        .then((data) => {
          if (data) {
            setPassModal(false);
            setSucModal(true);
          }
        });
    } else {
      setErrorPass(true);
    }
  }, [hideCaptcha, password, deletingId]);

  const deleteModal = (row: ICompany) => {
    setDeleteDialog(true);
    setDeletingId(row);
  };

  const setPass = (row: ICompany) => {
    setPassword('');
    setErrorPass(false);
    setPassModal(true);
    setDeletingId(row);
  };

  const handleSelect = (row: ICompany | UserRow, key: string) => {
    if (key === 'password') {
      setPass(row as ICompany);
    }

    if (key === 'delete') {
      deleteModal(row as ICompany);
    }
  };

  useEffect(() => {
    if (errorPass && password) {
      if (validPassword(password)) {
        setErrorPass(false);
      }
    }
  }, [password, errorPass]);

  const columns = useMemo(() => {
    if (users) {
      return [
        {
          key: 'name',
          label: 'Full name',
          sortable: true,
          width: '20%',
          render: ({ name, surname }: UserRow) => (
            <StyledCellText>
              {name} {surname}
            </StyledCellText>
          ),
        },
        {
          key: 'email',
          label: 'Email',
          sortable: false,
          width: '20%',
          render: ({ email }: UserRow) => (
            <StyledCellText>{email}</StyledCellText>
          ),
        },
        {
          key: 'company',
          label: 'Company',
          sortable: false,
          width: '25%',
          render: ({ company }: { company: UserCompany[] }) =>
            company?.map(({ name, company_hs_id }: UserCompany) => (
              <StyledCellContainer>
                <Link to={`/company/${company_hs_id}`} $textDecoration="none">
                  <StyledCellText $textType="link">{name}</StyledCellText>
                </Link>
              </StyledCellContainer>
            )) || '',
        },
        {
          key: 'logged',
          label: 'Last logged',
          sortable: false,
          width: '20%',
          render: ({ last_logged_time }: UserRow) => (
            <StyledCellContainer>
              {last_logged_time ? (
                formatDate(last_logged_time, { time: '' })
              ) : (
                <span />
              )}
            </StyledCellContainer>
          ),
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          width: '15%',
          render: (row: UserRow | ICompany) => (
            <StyledCellContainer $right>
              {isSuperAdmin ? (
                <StyledCellContainer gap="24px !important" $hStack>
                  <ButtonMenu
                    options={getContactDotsOptions(null)}
                    onSelect={(k: string) => handleSelect(row as ICompany, k)}
                  />
                </StyledCellContainer>
              ) : null}
            </StyledCellContainer>
          ),
        },
      ];
    }

    return [
      {
        key: 'company',
        label: 'Company',
        sortable: false,
        width: '30%',
        render: ({ company_hs_id, name }: ICompany) => (
          <Link to={`/company/${company_hs_id}`} $textDecoration="none">
            <StyledCellText $textType="link">{name}</StyledCellText>
          </Link>
        ),
      },
      {
        key: 'invoiceQbNumber',
        label: 'Paid invoices',
        greyText: 'Number of invoices',
        sortable: false,
        width: '18%',
        render: ({ billing_info, currency }: ICompany) =>
          currency && (
            <StyledCellContainer $vStack $pr="40px" $right>
              <StyledCellText>
                {currencyFormatter(
                  billing_info ? billing_info.paid_invoices_total_amount : 0,
                  currency
                )}
              </StyledCellText>
              <StyledCellText $textType="grey">
                Invoices:{' '}
                {billing_info ? billing_info.paid_invoices_total_count : 0}
              </StyledCellText>
            </StyledCellContainer>
          ),
      },
      {
        key: 'invoices',
        label: 'Outstanding invoices',
        greyText: 'Number of invoices',
        sortable: false,
        width: '20%',
        render: ({ billing_info, currency }: ICompany) =>
          currency && (
            <StyledCellContainer $vStack $pr="40px" $right>
              <StyledCellText>
                {currencyFormatter(
                  billing_info
                    ? billing_info.outstanding_invoices_total_amount
                    : 0,
                  currency
                )}
              </StyledCellText>
              <StyledCellText $textType="grey">
                Invoices:{' '}
                {billing_info
                  ? billing_info.outstanding_invoices_total_count
                  : 0}
              </StyledCellText>
            </StyledCellContainer>
          ),
      },
      {
        key: 'autopayment',
        label: 'Auto payment status',
        greyText: 'Auto payment type',
        sortable: false,
        width: '20%',
        render: ({
          is_auto_payment,
          main_payment_method,
          currency,
        }: ICompany) =>
          currency && (
            <StyledCellContainer $vStack $disabled>
              <StyledCellText
                $color={is_auto_payment ? undefined : palette.error[700]}
              >
                {is_auto_payment ? 'Enabled' : 'Disabled'}
              </StyledCellText>
              {is_auto_payment && (
                <StyledCellText $textType="grey">
                  {getPaymentMethod(main_payment_method)}
                </StyledCellText>
              )}
            </StyledCellContainer>
          ),
      },
      {
        key: 'actions',
        label: '',
        greyText: '',
        sortable: false,
        width: '12%',
        render: ({ users_count, currency, sync_with_zconnect }: ICompany) => (
          <StyledCellContainer $hStack $right>
            {!sync_with_zconnect && (
              <Tooltip title="Broken integration with Hubspot">
                <Box>
                  <LinkIcon color={palette.error[500]} />
                </Box>
              </Tooltip>
            )}
            <Box>
              <ContactIcon count={users_count} />
            </Box>
            <Box>
              <CurrencySvgIcon currency={currency} />
            </Box>
          </StyledCellContainer>
        ),
      },
    ];
  }, []);

  function getPaymentMethod(method?: string) {
    if (method === 'sepa_debit') return 'SEPA';
    return method === 'ach_debit' ? 'ACH' : 'Credit card';
  }

  return (
    <>
      <StyledTableContainer>
        <Table
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          loading={loading}
          data={companiesRow}
          columns={columns}
          idKey="company_hs_id"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        />

        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100, 200]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>

      <DeleteContactModal
        open={deleteDialog}
        user={deletingId as unknown as UserRow}
        onSubmit={showDeleteCaptchaDialog}
        onClose={() => setDeleteDialog(false)}
      />

      <ChangePasswordModal
        open={passModal}
        user={deletingId as unknown as UserRow}
        value={password}
        onChange={setPassword}
        error={errorPass}
        onClose={() => setPassModal(false)}
        onSubmit={showCaptchaDialog}
      />

      <PasswordChangedModal
        open={sucModal}
        user={deletingId as unknown as UserRow}
        password={password}
        onClose={() => setSucModal(false)}
      />

      {showCaptcha ? (
        <CaptchaModal onComplete={confirm} onClose={hideCaptcha} />
      ) : (
        <span />
      )}

      {showDeleteCaptcha ? (
        <CaptchaModal onComplete={deleteMethod} onClose={hideDeleteCaptcha} />
      ) : (
        <span />
      )}
    </>
  );
};

export default Companies;
