import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';

export const OPTIONS_LIMIT = 5;
export const defaultFilterOptions = createFilterOptions<CloudOpsProjectsType>();

export const keys = [
  'minimum_monthly_budget',
  'over_budget_hourly_rate',
] as const;

export const filterOptions = (
  options: CloudOpsProjectsType[],
  state: FilterOptionsState<CloudOpsProjectsType>
) => defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
