/* eslint-disable camelcase */
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import cloneDeep from 'lodash-es/cloneDeep';
import {
  Grid,
  MenuItem,
  TextField,
  Stack,
  CircularProgress,
} from '@mui/material';
import { PageWrapperMinWidth } from 'templates/PageWrapper/PageWrapper';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import { validEmail } from 'utils/helpers/formValidators';
import dayjs from 'dayjs';
import axios from 'axios';
import { getRoleLabel, roles, ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import RolesTable from 'organisms/RolesTable/RolesTable';
import { AntSwitch } from 'atoms/AntSwitch';
import { useBaoSelector } from 'utils/hooks/redux';
import {
  StyledInput,
  StyledSwitchButton,
  StyledTableButton,
} from './SettingsPage.styles';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import Block from 'molecules/Card';
import HeadlessTable from 'molecules/Table/HeadlessTable';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import { StyledCellContainer } from 'molecules/Table/styles';

type Synchronizaton = {
  id: number;
  name: string;
  type: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
};

type AutoPaymentData = {
  is_retry_enabled: boolean;
  retry_days: number;
};

const userFormDefaultValue = {
  firstName: { value: '', error: '' },
  lastName: { value: '', error: '' },
  email: { value: '', error: '' },
  role: { value: '', error: '' },
};

const SettingsPage = () => {
  const { value: user } = useBaoSelector((state) => state.user);
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [addingUser, setAddingUser] = useState(false);
  const [userForm, setUserForm] = useState(userFormDefaultValue);
  const [synchronizations, setSynchronizations] = useState<Synchronizaton[]>();
  const [autoPaymentSettings, setAutoPaymentSettings] =
    useState<AutoPaymentData>({
      is_retry_enabled: false,
      retry_days: 0,
    });

  const [notification, setNotification] = useState({
    value: false,
    loading: false,
    modal: false,
  });

  const getAutoPaymentData = async () => {
    const { data } = await axios.get<AutoPaymentData>(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/auto-payment-settings`
    );
    setAutoPaymentSettings(data);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get<{ synchronizations: Synchronizaton[] }>(
        `${process.env.REACT_APP_USER_SERVICE}/synchronizations`
      );
      setSynchronizations(response.data.synchronizations);

      if (user.userType === SUPER_ADMIN) {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/settings`
        );
        setNotification((prev) => ({
          ...prev,
          value: data.email_notifications_enabled,
        }));
      }
    }

    fetchData();
    getAutoPaymentData();
  }, []);

  const changeAutoPaymentSettings = async (
    e: React.SyntheticEvent<EventTarget>,
    flag: boolean
  ) => {
    setAutoPaymentSettings({
      ...autoPaymentSettings,
      is_retry_enabled: flag,
    });

    await axios.put(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/auto-payment-settings`,
      {
        is_retry_enabled: flag,
        retry_days: autoPaymentSettings.retry_days,
      }
    );
  };

  const onChangeDays = async ({
    target: { value },
  }: {
    target: { value: string };
  }) => {
    const num = Number(value);
    if (!Number.isNaN(num) && num < 1000) {
      setAutoPaymentSettings({
        ...autoPaymentSettings,
        retry_days: num,
      });

      if (autoPaymentSettings.is_retry_enabled && num > 0) {
        await axios.put(
          `${process.env.REACT_APP_BILLING_SERVICE}/payment/auto-payment-settings`,
          {
            is_retry_enabled: autoPaymentSettings.is_retry_enabled,
            retry_days: num,
          }
        );
      }
    }
  };

  const handleClickSwitch = () => {
    /** If notification is on, turn it off */
    if (!notification.value) {
      handleChangeNotification();
      return;
    }

    /** If notification is off, show modal to confirm */
    setNotification((prev) => ({ ...prev, modal: true }));
  };

  const handleChangeNotification = async () => {
    setNotification((prev) => ({ ...prev, loading: true }));
    await axios.put(
      `${process.env.REACT_APP_BILLING_SERVICE}/payment/settings`,
      {
        email_notifications_enabled: !notification.value,
      }
    );

    setNotification((prev) => ({
      ...prev,
      loading: false,
      value: !prev.value,
    }));
    handleClose();
  };

  const Icon = () => (
    <StyledSwitchButton $active={notification.loading}>
      {notification.loading && (
        <CircularProgress size={10} color="secondary" thickness={6} />
      )}
    </StyledSwitchButton>
  );

  const syncColumns = [
    {
      id: 'name',
      key: 'name',
      sortable: false,
      label: 'Name',
      render: ({ name }: { name: string }) => (
        <StyledCellText>{name}</StyledCellText>
      ),
    },
    {
      id: 'updated_at',
      key: 'updated_at',
      sortable: false,
      label: 'updated_at',
      render: ({ updated_at }: { updated_at: string }) => (
        <StyledCellContainer $hStack>
          <StyledCellText>
            {updated_at ? dayjs(updated_at).format('MMMM DD, YYYY') : <span />}
          </StyledCellText>
        </StyledCellContainer>
      ),
    },
  ];

  const ruleData = [
    {
      days: (
        <StyledCellContainer $hStack $left sx={{ gap: '10px !important' }}>
          <StyledCellContainer>
            Retry failed auto payment every
          </StyledCellContainer>
          <StyledInput
            value={autoPaymentSettings.retry_days}
            onChange={onChangeDays}
            inputProps={{ min: 0, max: 999 }}
            disabled={user.userType === ADMIN}
          />
          <StyledCellText>days</StyledCellText>
        </StyledCellContainer>
      ),
      value: (
        <Stack sx={{ alignItems: 'flex-end' }}>
          <AntSwitch
            checked={autoPaymentSettings.is_retry_enabled}
            onChange={changeAutoPaymentSettings}
            disabled={user.userType === ADMIN}
          />
        </Stack>
      ),
    },
    {
      days: <StyledCellText>Enable all customers notifications</StyledCellText>,
      value: (
        <Stack sx={{ alignItems: 'flex-end' }}>
          <AntSwitch
            checked={notification.value}
            checkedIcon={<Icon />}
            icon={<Icon />}
            disabled={user.userType === ADMIN}
            inputProps={{ 'aria-label': 'controlled' }}
            onClick={handleClickSwitch}
          />
        </Stack>
      ),
    },
  ];

  const ruleColumns = [
    {
      id: 'days',
      key: 'days',
      sortable: false,
      label: 'Days',
      render: (item: { days: ReactNode }) => item.days,
    },
    {
      id: 'value',
      key: 'value',
      sortable: false,
      label: 'Value',
      render: (item: { value: ReactNode }) => item.value,
    },
  ];

  const handleClose = useCallback(() => {
    setAddingUser(false);
    setShowForm(false);
    setUserForm(userFormDefaultValue);
    setNotification((prev) => ({ ...prev, modal: false }));
  }, []);

  const showModalCaptcha = useCallback(() => {
    setShowForm(false);
    setShowCaptcha(true);
  }, []);

  const hideModal = useCallback(() => {
    setAddingUser(true);
    setShowCaptcha(false);
  }, []);

  const handleCreatingUser = (e: FormEvent) => {
    e.preventDefault();
    const updatedForm = cloneDeep(userForm);
    let error = false;
    if (!validEmail(userForm.email.value)) {
      updatedForm.email.error = 'Please enter valid email address!';
      error = true;
    }
    if (!userForm.firstName.value) {
      updatedForm.firstName.error = 'Please enter your first name';
      error = true;
    }
    if (!userForm.lastName.value) {
      updatedForm.lastName.error = 'Please enter your last name';
      error = true;
    }
    if (!userForm.role.value) {
      updatedForm.role.error = 'Please select a role';
      error = true;
    }
    setUserForm(updatedForm);
    if (error) {
      return false;
    }

    showModalCaptcha();

    return null;
  };

  const handleSubmit = useCallback(() => {
    hideModal();

    // todo add catch block
    axios
      .post(`${process.env.REACT_APP_USER_SERVICE}/create`, {
        name: userForm.firstName.value,
        surname: userForm.lastName.value,
        email: userForm.email.value,
        user_type: userForm.role.value,
      })
      .then(handleClose);
  }, [userForm, handleClose, hideModal]);

  const handleUserFormInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof typeof userForm
  ) => {
    const updatedForm = cloneDeep(userForm);
    const newValue = e.target.value;
    if (field === 'email') {
      if (validEmail(newValue)) {
        updatedForm.email.error = '';
      }
    } else if (field === 'firstName') {
      if (newValue) {
        updatedForm.firstName.error = '';
      }
    } else if (field === 'lastName') {
      if (newValue) {
        updatedForm.lastName.error = '';
      }
    } else if (field === 'role') {
      if (newValue) {
        updatedForm.role.error = '';
      }
    }
    updatedForm[field].value = newValue;
    setUserForm(updatedForm);
  };

  return (
    <PageWrapperMinWidth title="Settings">
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <Block
            avatar={<CardAvatar />}
            title="Rules"
            sx={{
              '& .MuiCardContent-root': {
                height: '232px',
              },
            }}
          >
            <HeadlessTable
              idKey="id"
              hover={false}
              columns={ruleColumns}
              data={ruleData}
              sx={{
                '& .MuiTableCell-root': {
                  padding: '12px 0 !important',
                },
                '& .MuiCardContent-root': {
                  height: '312px',
                },
              }}
            />
          </Block>
        </Grid>
        <Grid item xs={5}>
          <Block
            avatar={<CardAvatar />}
            title="Synchronization"
            sx={{
              '& .MuiCardContent-root': {
                height: '232px',
              },
            }}
          >
            <HeadlessTable
              idKey="id"
              hover={false}
              emptyText=""
              columns={syncColumns}
              data={synchronizations || []}
              sx={{
                '& .MuiTableCell-root': {
                  padding: '12px 0 !important',
                },
                '& .MuiTableRow-root:last-child .MuiTableCell-root': {
                  borderBottom: '0 !important',
                },
              }}
            />
          </Block>
        </Grid>
        <Grid item xs={12}>
          <Block
            avatar={<CardAvatar />}
            title="Admins"
            action={
              user.userType !== ADMIN && (
                <StyledTableButton
                  variant="contained"
                  onClick={() => setShowForm(true)}
                >
                  Add new
                </StyledTableButton>
              )
            }
          >
            <RolesTable addingUser={addingUser} />
          </Block>
        </Grid>
      </Grid>

      <Modal
        padding24
        open={showForm}
        maxWidth="md"
        title="Add new admin user"
        onClose={handleClose}
      >
        <>
          <ModalContent>
            <TextField
              fullWidth
              size="small"
              autoComplete="given-name"
              margin="normal"
              label="First name"
              InputLabelProps={{
                shrink: true,
              }}
              value={userForm.firstName.value}
              error={!!userForm.firstName.error}
              helperText={userForm.firstName.error}
              onChange={(event) =>
                handleUserFormInputChange(event, 'firstName')
              }
            />
            <TextField
              fullWidth
              size="small"
              autoComplete="family-name"
              margin="normal"
              label="Last name"
              InputLabelProps={{
                shrink: true,
              }}
              value={userForm.lastName.value}
              error={!!userForm.lastName.error}
              helperText={userForm.lastName.error}
              onChange={(event) => handleUserFormInputChange(event, 'lastName')}
            />
            <TextField
              fullWidth
              size="small"
              autoComplete="email"
              margin="normal"
              label="Email"
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              value={userForm.email.value}
              error={!!userForm.email.error}
              helperText={userForm.email.error}
              onChange={(event) => handleUserFormInputChange(event, 'email')}
            />
            <TextField
              fullWidth
              select
              size="small"
              margin="normal"
              label="Status"
              InputLabelProps={{
                shrink: true,
              }}
              value={userForm.role.value}
              error={!!userForm.role.error}
              helperText={userForm.role.error}
              onChange={(event) => handleUserFormInputChange(event, 'role')}
            >
              {roles.map((option) => (
                <MenuItem key={option} value={option}>
                  {getRoleLabel(option)}
                </MenuItem>
              ))}
            </TextField>
          </ModalContent>
          <ModalActions $padding24>
            <ModalCloseButton
              autoFocus
              variant="outlined"
              onClick={handleClose}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton variant="contained" onClick={handleCreatingUser}>
              Add
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      <Modal
        maxWidth="sm"
        open={notification.modal}
        onClose={handleClose}
        title="Disable customer notifications"
        subtitle="If notifications are disabled customer users will not receive any notifications like ‘Invoice status update’, ‘Invitation to Zazmic-Connect’, ‘Auto payment confirmation’, etc."
      >
        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={handleClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton
            disabled={notification.loading}
            variant="contained"
            onClick={handleChangeNotification}
          >
            Confirm
          </ModalSubmitButton>
        </ModalActions>
      </Modal>

      {showCaptcha ? (
        <CaptchaModal onComplete={handleSubmit} onClose={hideModal} />
      ) : (
        <span />
      )}
    </PageWrapperMinWidth>
  );
};

export default SettingsPage;
