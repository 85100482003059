import React, { FC } from 'react';

import { palette } from 'utils/styles/variables';

import { ReactComponent as MoneyIcon } from 'assets/icons/money.svg';
import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { ReactComponent as UsdIcon } from 'assets/icons/usd.svg';
import { ReactComponent as EuroIcon } from 'assets/icons/euro.svg';
import { ReactComponent as GBPIcon } from 'assets/icons/gbp.svg';

const CurrencySvgIcon: FC<{ currency?: string }> = ({ currency }) => {
  if (!currency) {
    return <CurrencyIcon color={palette.error[500]} />;
  }

  if (currency === 'USD') {
    return <UsdIcon />;
  }

  if (currency === 'EUR') {
    return <EuroIcon />;
  }

  if (currency === 'GBP') {
    return <GBPIcon />;
  }

  return <MoneyIcon />;
};

export default CurrencySvgIcon;
