import { Stack } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Companies from 'organisms/Companies/Companies';
import { HeadlessPageWrapper } from 'templates/PageWrapper/PageWrapper';
import { StyledPaper } from './Companies.styles';
import CurrencyStatusSelect from 'atoms/StatusSelect/CurrencyStatusSelect';
import { FilterAutocomplete } from 'atoms/TableFilter';
import { debounce } from 'lodash-es';
import {
  StyledFilterDetailsText,
  StyledFilterWrapper,
} from 'molecules/Filter/Filter.styles';

const CompaniesPage = () => {
  const [companies, setCompanies] = useState([]);
  const [companiesRow, setCompaniesRow] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState<string | undefined>('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState<boolean>(false);
  const [sort] = useState('all');
  const [currency, setCurrency] = useState<string>('');

  /** debounce handler for autocomplete */
  const debouncedChangeHandler = useCallback(
    debounce((event, value) => {
      setSearch(value || event.target.value);
    }, 300),
    []
  );

  const onReset = useCallback(() => {
    setSearch('');
  }, []);

  useEffect(() => {
    if (!search) {
      setCompanies([]);
      return;
    }

    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company`, {
        params: {
          name: search,
          auto_payment: sort,
          limit: 5,
        },
      })
      .then(({ data }) => {
        if (data.companies) {
          setCompanies(
            data.companies.map(({ name }: { name: string }) => name)
          );
        } else {
          setCompanies([]);
        }
      });
  }, [search, sort]);

  useEffect(() => {
    setPage(0);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_USER_SERVICE}/company`, {
        params: {
          name: search,
          page: page + 1,
          auto_payment: sort,
          currency: currency === 'all' ? undefined : currency,
          limit,
        },
      })
      .then(({ data }) => {
        setCount(data.count);
        setCompaniesRow(data.companies);
      })
      .finally(() => setLoading(false));
  }, [search, page, limit, sort, currency]);

  const isDetailsVisible = (() => {
    if (loading) return false;
    if (search) return true;
    return !!(currency && currency !== 'all');
  })();

  return (
    <HeadlessPageWrapper>
      <StyledPaper>
        <StyledFilterWrapper>
          <Stack direction="row" gap={3}>
            <CurrencyStatusSelect
              onChange={(selectedCurrency) => {
                setPage(0);
                setCurrency(selectedCurrency);
              }}
            />
            <FilterAutocomplete
              isFreeSolo={!!search}
              value={search}
              options={companies}
              placeholder="Search by: Company name"
              tooltip="Filter by company name"
              onChange={debouncedChangeHandler}
              onReset={onReset}
            />
          </Stack>
          {isDetailsVisible ? (
            <StyledFilterDetailsText>
              Companies: <span>{count}</span>
            </StyledFilterDetailsText>
          ) : null}
        </StyledFilterWrapper>

        <Companies
          loading={loading}
          setLimit={setLimit}
          setPage={setPage}
          companiesRow={companiesRow}
          totalCount={count}
          page={page}
        />
      </StyledPaper>
    </HeadlessPageWrapper>
  );
};

export default CompaniesPage;
