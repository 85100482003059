import React, { FC, useState } from 'react';

import Modal from 'molecules/Modal';
import { ModalContent } from 'molecules/Modal/Modal.styles';
import { IconButton, Stack } from '@mui/material';
import {
  StyledRow,
  StyledRowText,
  StyledTextButton,
  DialogSectionTitle,
} from 'organisms/ProjectDialogs/CloudOpsProject.styles';
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { neutrals } from 'utils/styles/color';
import axios from 'axios';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';

import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';
import { palette } from 'utils/styles/variables';
import CloudOpsProjectInfoDialog from 'organisms/ProjectDialogs/CloudOpsProjectInfoDialog';
import CloudOpsProjectStatusDialog from 'organisms/ProjectDialogs/CloudOpsProjectStatusDialog';
import CloudOpsProjectTermsDialog from 'organisms/ProjectDialogs/CloudOpsProjectTermsDialog';
import QuickBookProjectSyncDialog from 'organisms/ProjectDialogs/QuickBookProjectSyncDialog';
import CloudOpsProjectDeleteDialog from 'organisms/ProjectDialogs/CloudOpsProjectDeleteDialog';

const CloudOpsProjectDetailsDialog: FC<{
  currency?: keyof typeof currencyIcon;
  onClose: () => void;
  onUpdate: () => void;
  project: CloudOpsProjectsType;
  companyHsId?: string;
}> = ({ currency, onClose, onUpdate, project, companyHsId }) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [open, setOpen] = useState<boolean>(true);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showSyncDialog, setShowSyncDialog] = useState<boolean>(false);
  const [showStatusChange, setStatusChange] = useState<boolean>(false);
  const [showTermsDialog, setShowTermsDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const {
    name,
    key,
    // quickbook_project_id,
    monthly_budget_in_hours,
    minimum_monthly_budget,
    over_budget_hourly_rate,
    quickbook_project_name,
    status,
    report_count,
  } = project;

  const onInfoClick = () => {
    setShowInfo(true);
    setOpen(false);
  };

  const onStatusBtnClick = () => {
    setStatusChange(true);
    setOpen(false);
  };

  const onDeleteBtnClick = async () => {
    setShowDeleteDialog(true);
    setOpen(false);
  };

  const onDeleteProject = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_USER_SERVICE}/projects/${project.id}`
      );

      onUpdate();
      onClose();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setShowDeleteDialog(false);
    }
  };

  const onTermsBtnClick = () => {
    setShowTermsDialog(true);
    setOpen(false);
  };

  const onSyncBtnClick = () => {
    setShowSyncDialog(true);
    setOpen(false);
  };

  const onCloseDialog = () => {
    setStatusChange(false);
    setShowTermsDialog(false);
    setShowSyncDialog(false);
    setShowDeleteDialog(false);
    setShowInfo(false);

    onUpdate();
    setOpen(true);
  };

  const termColor = status === 'active' ? neutrals[800] : neutrals[400];
  const isAbleDelete = (() => {
    if (!isSuperAdmin) return false;
    return !report_count || report_count <= 1;
  })();

  return (
    <>
      <Modal
        open={open}
        padding24
        maxWidth="sm"
        title="Project settings"
        onClose={onClose}
        transitionDuration={0}
      >
        <>
          <ModalContent sx={{ padding: '0 16px 24px !important' }}>
            <Stack gap="8px">
              <StyledRow>
                <StyledRowText>{name}</StyledRowText>
                <StyledRowText $color={neutrals[400]}>{key}</StyledRowText>
              </StyledRow>

              <StyledRow>
                <DialogSectionTitle $custom sx={{ fontSize: '0.875rem' }}>
                  CloudOps project contract terms
                </DialogSectionTitle>
                {status === 'active' && (
                  <StyledTextButton variant="text" onClick={onTermsBtnClick}>
                    Edit terms
                  </StyledTextButton>
                )}
              </StyledRow>

              <StyledRow>
                <StyledRowText $color={termColor}>
                  Monthly hours budget
                </StyledRowText>
                <StyledRowText $color={termColor}>
                  {monthly_budget_in_hours}{' '}
                  {monthly_budget_in_hours <= 1 ? 'hr' : 'hrs'}
                </StyledRowText>
              </StyledRow>

              <StyledRow>
                <StyledRowText $color={termColor}>
                  Base monthly Fee
                </StyledRowText>
                <StyledRowText $color={termColor}>
                  {currencyFormatter(Number(minimum_monthly_budget), currency)}
                </StyledRowText>
              </StyledRow>

              <StyledRow>
                <StyledRowText $color={termColor}>
                  Over budget hourly rate
                </StyledRowText>
                <StyledRowText $color={termColor}>
                  {currencyFormatter(Number(over_budget_hourly_rate), currency)}
                </StyledRowText>
              </StyledRow>

              <DialogSectionTitle sx={{ fontSize: '0.875rem' }}>
                Actions
              </DialogSectionTitle>

              <StyledRow>
                <StyledRowText>
                  QB project name:{' '}
                  <StyledRowText
                    $bold={!!quickbook_project_name}
                    $color={quickbook_project_name ? neutrals[800] : '#F19D41'}
                  >
                    {quickbook_project_name || 'No project synced'}
                  </StyledRowText>
                </StyledRowText>
                <StyledTextButton variant="text" onClick={onSyncBtnClick}>
                  {quickbook_project_name ? 'Edit' : 'Sync'}
                </StyledTextButton>
              </StyledRow>

              <StyledRow>
                <StyledRowText>
                  Project status:
                  <StyledRowText
                    $color={
                      status === 'active' ? palette.success[500] : '#F48319'
                    }
                  >
                    {status === 'active' ? ' Active' : ' Deactivated'}
                    <IconButton
                      sx={{ padding: '0' }}
                      aria-label="info"
                      onClick={onInfoClick}
                    >
                      <InfoIcon
                        height={18}
                        color={
                          status === 'active' ? palette.success[150] : '#FFB672'
                        }
                      />
                    </IconButton>
                  </StyledRowText>
                </StyledRowText>
                <StyledTextButton variant="text" onClick={onStatusBtnClick}>
                  {status === 'active' ? ' Deactivate' : ' Activate'}
                </StyledTextButton>
              </StyledRow>
              {isAbleDelete && (
                <StyledRow>
                  <StyledRowText>No invoices yet been created</StyledRowText>
                  <StyledTextButton variant="text" onClick={onDeleteBtnClick}>
                    Delete project
                  </StyledTextButton>
                </StyledRow>
              )}
            </Stack>
          </ModalContent>
        </>
      </Modal>

      {showInfo && <CloudOpsProjectInfoDialog onClose={onCloseDialog} />}

      {showStatusChange && (
        <CloudOpsProjectStatusDialog
          project={project}
          onClose={onCloseDialog}
        />
      )}

      {showTermsDialog && (
        <CloudOpsProjectTermsDialog
          mode="edit"
          project={project}
          onClose={onCloseDialog}
          currency={currency}
          companyHsId={companyHsId}
        />
      )}

      {/* Sync project dialog */}
      {showSyncDialog && (
        <QuickBookProjectSyncDialog
          project={project}
          companyHsId={companyHsId}
          onClose={onCloseDialog}
          onSync={onUpdate}
        />
      )}

      {showDeleteDialog && (
        <CloudOpsProjectDeleteDialog
          onClose={onCloseDialog}
          onDelete={onDeleteProject}
        />
      )}
    </>
  );
};

export default CloudOpsProjectDetailsDialog;
