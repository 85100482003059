import styled from 'styled-components';
import { Paper, Box, Typography } from '@mui/material';

const StyledPagePaper = styled(Paper)``;

const StyledTableWrapper = styled(Box)`
  padding-top: 12px;
`;
const StyledDetailsContainer = styled(Box)<{
  $firstCol?: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: right;
    ${({ $firstCol }) =>
      $firstCol &&
      `
      height: 100%;
      justify-content: center;
      text-align: left;
      gap: 16px;
    `}
  }
`;

const StyledReportInfo = styled(Typography)`
  && {
    overflow: hidden;
    color: #f19d41;
    text-overflow: ellipsis;

    /* New/TableTextDefault */
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
`;
export {
  StyledDetailsContainer,
  StyledPagePaper,
  StyledTableWrapper,
  StyledReportInfo,
};
