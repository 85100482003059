import React, { FC, useCallback, useState } from 'react';

import { Alert } from '@mui/material';
import Modal from 'molecules/Modal';
import { ZStreamProjectAutoComplete } from 'molecules/ProjectAutoComplete/ZStreamProjectAutoComplete';

import { invoiceInitialDetails } from 'pages/CompanyPage/helpers';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoSelector } from 'utils/hooks/redux';
import { keys } from 'organisms/ProjectDialogs/utils';
import axios from 'axios';

import {
  InvoiceInputNameType,
  ZStreamProjectSyncDialogProps,
} from 'organisms/ProjectDialogs/types';
import { CloudOpsProjectsType, FormType } from 'pages/CompanyPage/types';

import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { DialogSectionTitle } from 'organisms/ProjectDialogs/CloudOpsProject.styles';
import { WhiteBorderTextField } from 'pages/CompanyPage/CompanyPage.styles';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';

const regExp = /^\d*\.?\d*$/;

const CloudOpsProjectDialog: FC<ZStreamProjectSyncDialogProps> = ({
  project,
  currency,
  companyHsId,
  onClose,
}) => {
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [alert, setAlert] = useState<{ error: boolean; message: string }>({
    error: false,
    message: '',
  });

  const [form, setForm] = useState<FormType>(invoiceInitialDetails);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] = useState<
    string | CloudOpsProjectsType | null
  >(null);

  const handleCreateProject = useCallback(async () => {
    setFormSubmitted(true);
    if (
      cloudOpsProjectSelected &&
      typeof cloudOpsProjectSelected === 'object'
      // && Number(form.monthly_budget_in_hours) > 0
      // && Number(form.monthly_budget_in_hours) <= 160
    ) {
      try {
        await axios.post(`${process.env.REACT_APP_USER_SERVICE}/projects`, {
          company_hs_id: Number(companyHsId),
          project_id:
            typeof cloudOpsProjectSelected === 'object'
              ? cloudOpsProjectSelected?.id
              : 0,
          minimum_monthly_budget: form.minimum_monthly_budget
            ? Number(form.minimum_monthly_budget)
            : 0,
          monthly_budget_in_hours: form.monthly_budget_in_hours
            ? Number(form.monthly_budget_in_hours)
            : 0,
          over_budget_hourly_rate: form.over_budget_hourly_rate
            ? Number(form.over_budget_hourly_rate)
            : 0,
        });

        setForm(invoiceInitialDetails);
        setFormSubmitted(false);

        setCloudOpsProjectSelected(null);

        onClose();
      } catch (err) {
        setAlert({ error: true, message: err.response.data.message });
        setTimeout(() => {
          setAlert({ error: false, message: '' });
        }, 3000);
      }
    }
  }, [cloudOpsProjectSelected, form, companyHsId, onClose]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      !/^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = value.slice(0, -1);
      return;
    }

    // Don't allow type nothing except number:
    if (
      name === 'monthly_budget_in_hours' &&
      /^[0-9\b]+$/.test(`${Number(value)}`)
    ) {
      event.target.value = Number(value);
    }

    // value can be empty
    // value should be decimal or integer
    // value should be more or equal to 0
    // key should be included in keys
    if (value === '' && keys.includes(name)) {
      setForm((prev) => ({ ...prev, [name]: Number(value) }));
      return;
    }

    if ((!regExp.test(value) || Number(value) < 0) && keys.includes(name)) {
      event.target.value = '';
      return;
    }

    setForm((prev) => ({ ...prev, [name]: Number(value) }));
  }, []);

  const handleSaveProjectChanges = useCallback(async () => {
    try {
      if (
        project.id &&
        Number(form.monthly_budget_in_hours) >= 0 &&
        Number(form.monthly_budget_in_hours) <= 1000
      ) {
        await axios
          .put(
            `${process.env.REACT_APP_USER_SERVICE}/projects/${project?.id}`,
            {
              minimum_monthly_budget: form.minimum_monthly_budget
                ? Number(form.minimum_monthly_budget)
                : 0,
              monthly_budget_in_hours: form.monthly_budget_in_hours
                ? Number(form.monthly_budget_in_hours)
                : 0,
              over_budget_hourly_rate: form.over_budget_hourly_rate
                ? Number(form.over_budget_hourly_rate)
                : 0,
            }
          )
          .then(() => {
            const projectDetailsCopy = { ...project };
            projectDetailsCopy.minimum_monthly_budget =
              form.minimum_monthly_budget ? form.minimum_monthly_budget : '';

            projectDetailsCopy.monthly_budget_in_hours =
              form.monthly_budget_in_hours ? form.monthly_budget_in_hours : '';

            projectDetailsCopy.over_budget_hourly_rate =
              form.over_budget_hourly_rate ? form.over_budget_hourly_rate : '';

            updateDialogInputs(projectDetailsCopy);
          });
      }
    } catch (error) {
      setAlert({ error: true, message: error.response.data.message });
      setTimeout(() => {
        setAlert({ error: false, message: '' });
      }, 3000);
    }
  }, [project, form]);

  const handleInputBlur = useCallback(
    (event) => {
      const {
        name,
        value,
      }: { name: InvoiceInputNameType; value: string | number } = event.target;

      if (value === '') {
        setForm((prev) => ({ ...prev, [name]: '0' }));
        event.target.value = currencyFormatter(
          Number.parseFloat('0'),
          currency || 'USD'
        );
        //   `${
        //   currencyIcon[currency || 'USD']
        // }${Number.parseFloat('0').toFixed(2)}`;
      } else if (Number(form[name]) >= 0) {
        event.target.value = currencyFormatter(
          Number.parseFloat(form[name]),
          currency || 'USD'
        );
        // `${
        //   currencyIcon[currency || 'USD']
        // }${Number.parseFloat(form[name]).toFixed(2)}`
      }

      handleSaveProjectChanges();
    },
    [form, handleSaveProjectChanges, currency]
  );

  const updateDialogInputs = (details: CloudOpsProjectsType) => {
    // If project 'id' isn't exists, then it's creation mode;
    if (!details.id) return;

    const {
      monthly_budget_in_hours,
      minimum_monthly_budget,
      over_budget_hourly_rate,
    } = details;

    // Set form fields:
    setCloudOpsProjectSelected(details);
    setForm({
      monthly_budget_in_hours,
      minimum_monthly_budget: minimum_monthly_budget || '0',
      over_budget_hourly_rate: over_budget_hourly_rate || '0',
    });
  };

  return (
    <Modal
      open
      maxWidth="sm"
      title="Add new CloudOps project"
      subtitle="Select the zStream project and set the contract terms. Once the new project is added, monthly CloudOps reports will be generated starting from the 1st of the current month. Subsequently, Zazmic-Connect will automatically generate invoices each following month."
      onClose={onClose}
      transitionDuration={0}
    >
      <>
        <ModalContent
          $gap={16}
          sx={{ overflowY: 'hidden', pb: '8px !important' }}
        >
          {alert.message ? (
            <Alert
              sx={{ width: 'auto !important', minWidth: 'auto !important' }}
              severity={alert.error ? 'error' : 'success'}
            >
              {alert.message}
            </Alert>
          ) : undefined}
          <ZStreamProjectAutoComplete
            disabled={false}
            submitted={formSubmitted}
            project={project}
            onChange={setCloudOpsProjectSelected}
          />
          <DialogSectionTitle
            sx={{ fontSize: '0.875rem', padding: '8px 0', lineHeight: '20px' }}
          >
            CloudOps project contract terms
          </DialogSectionTitle>
          <WhiteBorderTextField
            fullWidth
            size="small"
            disabled={!isSuperAdmin} // If not super admin then disable
            name="monthly_budget_in_hours"
            defaultValue={form.monthly_budget_in_hours}
            onChange={handleInputChange}
            onBlur={handleSaveProjectChanges}
            error={
              Number(form.monthly_budget_in_hours) < 0 ||
              Number(form.monthly_budget_in_hours) > 1000
            }
            helperText={
              Number(form.monthly_budget_in_hours) < 0 ||
              Number(form.monthly_budget_in_hours) > 1000
                ? 'Please enter a number between 0 and 1000.'
                : ''
            }
            InputProps={{
              inputProps: {
                style: { textAlign: 'right' },
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            autoComplete="given-name"
            margin="normal"
            label="Monthly budget in hours"
          />

          <WhiteBorderTextField
            fullWidth
            size="small"
            disabled={!isSuperAdmin} // If not super admin then disable
            name="minimum_monthly_budget"
            InputProps={{
              inputProps: {
                min: 0,
                style: { textAlign: 'right' },
              },
            }}
            defaultValue={(() => {
              if (form.minimum_monthly_budget === '') {
                return form.minimum_monthly_budget;
              }
              return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                form.minimum_monthly_budget
              ).toFixed(2)}`;
            })()}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={(e) => {
              e.target.value =
                Number(form.minimum_monthly_budget) > 0
                  ? form.minimum_monthly_budget
                  : '';
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              Number(form.minimum_monthly_budget) < 0 ||
              Number(form.minimum_monthly_budget) > 100000
            }
            helperText={
              Number(form.minimum_monthly_budget) < 0 ||
              Number(form.minimum_monthly_budget) > 100000
                ? 'Please enter a number between 0 and 100000.'
                : ''
            }
            margin="normal"
            label="Base monthly Fee"
          />

          <WhiteBorderTextField
            fullWidth
            size="small"
            disabled={!isSuperAdmin} // If not super admin then disable
            name="over_budget_hourly_rate"
            InputProps={{
              inputProps: {
                min: 0,
                style: { textAlign: 'right' },
              },
            }}
            defaultValue={(() => {
              if (form.over_budget_hourly_rate === '') {
                return form.over_budget_hourly_rate;
              }
              return `${currencyIcon[currency || 'USD']}${Number.parseFloat(
                form.over_budget_hourly_rate
              ).toFixed(2)}`;
            })()}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            onFocus={(e) => {
              e.target.value =
                Number(form.over_budget_hourly_rate) > 0
                  ? form.over_budget_hourly_rate
                  : '';
            }}
            InputLabelProps={{
              shrink: true,
            }}
            error={
              Number(form.over_budget_hourly_rate) < 0 ||
              Number(form.over_budget_hourly_rate) > 10000
            }
            helperText={
              Number(form.over_budget_hourly_rate) < 0 ||
              Number(form.over_budget_hourly_rate) > 10000
                ? 'Please enter a number between 0 and 10000.'
                : ''
            }
            margin="normal"
            label="Over budget hourly rate"
          />
        </ModalContent>

        <ModalActions>
          <ModalCloseButton autoFocus variant="outlined" onClick={onClose}>
            Cancel
          </ModalCloseButton>
          <ModalSubmitButton variant="contained" onClick={handleCreateProject}>
            Add project
          </ModalSubmitButton>
        </ModalActions>
      </>
    </Modal>
  );
};

export default CloudOpsProjectDialog;
