import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  Paper,
  Tab,
  Box,
  Grid,
  Grow,
  Stack,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

import CompanyContacts, {
  UserRow,
} from 'organisms/CompanyDetailsCard/CompanyContacts';

import {
  AlertBox,
  CompanyDetailsText,
  CompanyInfo,
  CompanyInfoDetails,
  CompanyInfoLeftColumnStyled,
  StyledBody2,
} from 'pages/CompanyPage/CompanyPage.styles';
import { GreyTxt } from 'utils/styles/GreyText.styles';
import {
  StyledCompanyDetailsButton,
  StyledCurrencyButton,
} from 'organisms/CompanyDetailsCard/CompanyDetailsCard.styles';
import { StyledTabList } from 'molecules/Card/Card.styles';

import { formatDate } from 'utils/helpers/dates';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import axios from 'axios';

import { companyType } from 'pages/CompanyPage/types';

import { ReactComponent as MapsHomeWorkOutlinedIcon } from 'assets/icons/adress.svg';
import { ReactComponent as MailOutlinedIcon } from 'assets/icons/email.svg';
import { ReactComponent as LanguageIcon } from 'assets/icons/domain.svg';
import { ReactComponent as GoogleIcon } from 'assets/icons/google.svg';
import { ReactComponent as BrokenLinkIcon } from 'assets/icons/broken-link.svg';
import { ReactComponent as HubspotIcon } from 'assets/icons/hubspot.svg';
import { ReactComponent as QuickbooksIcon } from 'assets/icons/quickbooks.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/money.svg';
import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { CaptchaModal } from 'molecules/CaptchaModal/CaptchaModal';
import { validPassword } from 'utils/helpers/formValidators';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { palette } from 'utils/styles/variables';
import DeleteContactModal from 'organisms/Companies/components/DeleteContactModal';
import ChangePasswordModal from 'organisms/Companies/components/ChangePasswordModal';
import PasswordChangedModal from 'organisms/Companies/components/PasswordChangedModal';
// import CurrencySetModal from 'organisms/Companies/components/CurrencySetModal';
import { shortenString } from 'utils/helpers/string';
import { neutrals } from 'utils/styles/color';
import Modal from 'molecules/Modal';
import {
  ModalActions,
  ModalCloseButton,
  ModalContent,
  ModalSubmitButton,
} from 'molecules/Modal/Modal.styles';
import { paymentCompanyOptions } from 'organisms/CompanyDetailsCard/data';

const RadioLabel = ({
  code,
  description,
}: {
  code: string;
  description: string;
}) => (
  <Box>
    <Typography
      variant="caption"
      sx={{
        fontFamily: 'Poppins, sans-serif',
        color: neutrals[700],
        fontSize: '0.875rem',
      }}
    >
      {code}
    </Typography>{' '}
    -{' '}
    <Typography
      variant="caption"
      sx={{
        fontFamily: 'Poppins, sans-serif',
        color: neutrals[500],
        fontSize: '0.875rem',
      }}
    >
      {description}
    </Typography>
  </Box>
);

const emptyState = { show: false, data: null };
const currencyState = { show: false, value: null };

export const CompanyDetailsCard: FC<{
  company: companyType;
  paymentMethod: string;
  onChange: () => Promise<void>;
}> = ({ company, paymentMethod, onChange }) => {
  const { hsId } = useParams() as { hsId: string };
  const { value: user } = useBaoSelector((state) => state.user);
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const [selectedTab, setSelectedTab] = useState<'details' | 'contacts'>(
    'details'
  );
  const [itemCopied, setItemCopied] = useState<string>('');

  const [users, setUsers] = useState([]);
  const [deleteUser, setDeleteUser] = useState<{
    show: boolean;
    data: UserRow | null;
  }>(emptyState);
  const [userChangePassword, setUserChangePassword] = useState<{
    show: boolean;
    data: UserRow | null;
  }>(emptyState);
  const [password, setPassword] = useState<{
    value: string;
    isError: boolean;
    isComplete: boolean;
  }>({
    value: '',
    isError: false,
    isComplete: false,
  });

  const [showCaptcha, setShowCaptcha] = useState<boolean>(false);
  const [showDeleteCaptcha, setShowDeleteCaptcha] = useState<boolean>(false);
  // Controls currency dialog state
  const [currencyDialog, setCurrencyDialog] = useState<{
    show: boolean;
    value: string | null;
  }>(currencyState);

  const handleChangeCurrency = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target as HTMLInputElement;
      setCurrencyDialog((prev) => ({ ...prev, value }));
    },
    []
  );

  const handleSubmitCurrency = useCallback(async () => {
    try {
      await axios.patch(
        `${process.env.REACT_APP_USER_SERVICE}/company/${hsId}`,
        {
          currency: currencyDialog.value,
        }
      );
      await onChange();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
    setCurrencyDialog(currencyState);
  }, [currencyDialog]);

  const handleChangeTabs = useCallback(
    (event: React.SyntheticEvent, newValue: 'details' | 'contacts') => {
      setSelectedTab(newValue);
    },
    []
  );

  const handleDeleteUser = useCallback((v: UserRow) => {
    setDeleteUser({ show: true, data: v });
  }, []);

  const handleUserChangePassword = useCallback((v: UserRow) => {
    setUserChangePassword({ show: true, data: v });
  }, []);

  const onClose = () => {
    setDeleteUser((prev) => ({ ...prev, show: false }));
    setUserChangePassword((prev) => ({ ...prev, show: false }));
  };

  const handleReset = useCallback(() => {
    setDeleteUser(emptyState);
    setUserChangePassword(emptyState);
  }, []);

  const showCaptchaDialog = useCallback(() => {
    setShowCaptcha(true);
    onClose();
  }, []);

  const showDeleteCaptchaDialog = useCallback(() => {
    setShowDeleteCaptcha(true);
    onClose();
  }, []);

  const handleConfirm = useCallback(() => {
    setShowCaptcha(false);

    if (validPassword(password.value)) {
      axios
        .patch(`${process.env.REACT_APP_USER_SERVICE}/update-user-password`, {
          password: password.value,
          user_id: userChangePassword.data?.id,
        })
        .then((data) => {
          if (data) {
            handleReset();
            setPassword((prev) => ({ ...prev, isComplete: true }));
          }
        });
    } else {
      setPassword((prev) => ({ ...prev, isError: true }));
    }
  }, [password, userChangePassword]);

  const handleDeleteConfirm = useCallback(() => {
    setShowDeleteCaptcha(false);
    handleReset();

    axios
      .delete(
        `${process.env.REACT_APP_USER_SERVICE}/delete/${deleteUser.data?.id}`
      )
      .then(fetchPageData);
  }, [deleteUser]);

  const handleUpdate = useCallback(async () => {
    await fetchPageData();
  }, [company.companyHsId]);

  useEffect(() => {
    (async () => {
      await fetchPageData();
    })();
  }, [company.companyHsId]);

  async function fetchPageData() {
    const { data } = await axios.get(
      `${process.env.REACT_APP_USER_SERVICE}/users`,
      {
        params: {
          page: 1,
          limit: 100,
          user_types: 'company_admin,company_viewer',
          company_hs_id: hsId,
          sort: 'created_at',
        },
      }
    );

    if (data.users) {
      setUsers(data.users);
    }
  }

  function renderAutopaymentType() {
    let result = '';

    if (company.auto_pay) {
      if (paymentMethod === 'sepa_debit') {
        result = 'SEPA';
      } else {
        result = paymentMethod === 'card' ? 'CC' : 'ACH';
      }
    }

    return result;
  }

  function copyToClipboard(value: string, prop: string) {
    navigator.clipboard.writeText(value);

    setItemCopied(prop);

    setTimeout(() => {
      setItemCopied('');
    }, 1500);
  }

  function mountFullAddress(companyAddress: companyType) {
    return [
      companyAddress.address,
      companyAddress.address2,
      companyAddress.city,
      companyAddress.state,
      companyAddress.country,
      companyAddress.postalCode,
    ].reduce((acc, item) => {
      if (item) return acc ? `${acc}, ${item}` : item;

      return acc;
    }, '');
  }

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  const billingId = !company.gcpBillingId?.includes('NO GCP')
    ? company.gcpBillingId.replace('gcp_billing_id_', '')
    : '';

  return (
    <Paper sx={{ pt: '16px', minHeight: '346px' }}>
      <TabContext value={selectedTab}>
        <StyledTabList
          onChange={handleChangeTabs}
          aria-label="Company Details and Contacts tabs"
          variant="fullWidth"
        >
          <Tab
            label="Details"
            {...a11yProps(0)}
            className={selectedTab === 'details' ? '.active' : ''}
            value="details"
          />
          <Tab
            label="Contacts"
            {...a11yProps(1)}
            className={selectedTab === 'contacts' ? '.active' : ''}
            value="contacts"
          />
        </StyledTabList>
        <TabPanel sx={{ padding: '16px', mt: 0 }} value="details">
          <Grid
            container
            spacing={2}
            sx={{
              '&>.MuiGrid-root.MuiGrid-item': {
                marginTop: '16px',
                paddingTop: 0,
              },
            }}
          >
            <CompanyInfoLeftColumnStyled item xs={8}>
              <CompanyInfo>
                <MapsHomeWorkOutlinedIcon
                  onClick={() =>
                    copyToClipboard(mountFullAddress(company), 'address')
                  }
                />
                <Box>
                  <Grow
                    in={itemCopied === 'address'}
                    style={{
                      transformOrigin: '0 0 0',
                      position: 'absolute',
                    }}
                  >
                    <AlertBox>Address copied</AlertBox>
                  </Grow>
                  {itemCopied !== 'address' && (
                    <StyledBody2 variant="body2" maxWidth="365px">
                      {shortenString(mountFullAddress(company), 160, '...')}
                    </StyledBody2>
                  )}
                </Box>
              </CompanyInfo>
              <CompanyInfo>
                <MailOutlinedIcon
                  onClick={() => copyToClipboard(company.email, 'email')}
                />
                <Box>
                  <Grow
                    in={itemCopied === 'email'}
                    style={{
                      transformOrigin: '0 0 0',
                      position: 'absolute',
                      maxWidth: '95%',
                    }}
                  >
                    <AlertBox>Email copied</AlertBox>
                  </Grow>
                  {itemCopied !== 'email' && (
                    <StyledBody2 variant="body2">
                      {shortenString(company.email, 100, '...')}
                    </StyledBody2>
                  )}
                </Box>
              </CompanyInfo>
              <CompanyInfo>
                <LanguageIcon
                  onClick={() => copyToClipboard(company.domain, 'domain')}
                />
                <Box>
                  <Grow
                    in={itemCopied === 'domain'}
                    style={{
                      transformOrigin: '0 0 0',
                      position: 'absolute',
                      maxWidth: '95%',
                    }}
                  >
                    <AlertBox>Domain copied</AlertBox>
                  </Grow>
                  {itemCopied !== 'domain' && (
                    <StyledBody2 variant="body2">
                      {shortenString(company.domain, 100, '...')}
                    </StyledBody2>
                  )}
                </Box>
              </CompanyInfo>
              <CompanyInfo>
                <GoogleIcon
                  onClick={() => copyToClipboard(billingId, 'gcpBillingId')}
                />
                <Box>
                  <Grow
                    in={itemCopied === 'gcpBillingId'}
                    style={{
                      transformOrigin: '0 0 0',
                      position: 'absolute',
                      width: '95%',
                    }}
                  >
                    <AlertBox>GCP account copied</AlertBox>
                  </Grow>
                  {itemCopied !== 'gcpBillingId' && (
                    <StyledBody2 variant="body2">
                      {shortenString(billingId, 40, '...')}
                    </StyledBody2>
                  )}
                </Box>
              </CompanyInfo>
              <CompanyInfo
                $color={
                  company?.currency ? palette.primary.main : palette.error[500]
                }
              >
                {company?.currency ? <MoneyIcon /> : <CurrencyIcon />}
                <Box>
                  {company?.currency ? (
                    <StyledBody2 variant="body2">
                      {shortenString(company?.currency, 40, '...')}
                    </StyledBody2>
                  ) : (
                    <>
                      {company.companyHsId && isSuperAdmin ? (
                        <StyledCurrencyButton
                          variant="text"
                          onClick={() =>
                            setCurrencyDialog({ show: true, value: null })
                          }
                        >
                          Select company currency
                        </StyledCurrencyButton>
                      ) : null}
                    </>
                  )}
                </Box>
              </CompanyInfo>
            </CompanyInfoLeftColumnStyled>
            <Grid item xs={4}>
              <CompanyInfoDetails>
                <GreyTxt>Customer since:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {company.since ? formatDate(company.since, { time: '' }) : ''}
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Zazmic GCP discount:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {company.discount}%
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Payment terms:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {company.paymentTerms}
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Auto payment:</GreyTxt>
                <CompanyDetailsText
                  ml="auto"
                  color={company.auto_pay ? '#047857' : '#B91C1C'}
                >
                  {company.auto_pay ? 'Enabled' : 'Disabled'}
                </CompanyDetailsText>
                {company.auto_pay && (
                  <CompanyDetailsText ml="5px">
                    {renderAutopaymentType()}
                  </CompanyDetailsText>
                )}
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Total billings:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {company.lifetime_billings}
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Total paid:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {currencyFormatter(
                    Number(
                      company.total_billing_amount
                        ? company.total_billing_amount
                        : 0
                    ),
                    company.currency
                  )}
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Total due:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {currencyFormatter(company.total_due, company.currency)}
                </CompanyDetailsText>
              </CompanyInfoDetails>
              <CompanyInfoDetails>
                <GreyTxt>Total past due:</GreyTxt>
                <CompanyDetailsText ml="auto">
                  {currencyFormatter(
                    Number(company.past_due ? company.past_due : 0),
                    company.currency
                  )}
                </CompanyDetailsText>
              </CompanyInfoDetails>
            </Grid>

            <Grid item>
              <Stack direction="row" sx={{ gap: '1rem', paddingTop: '1rem' }}>
                {company.hsCompanyLink ? (
                  <StyledCompanyDetailsButton
                    target="_blank"
                    href={company.hsCompanyLink}
                    variant="contained"
                    startIcon={
                      company.syncWithZconnect ? (
                        <HubspotIcon width={20} height={20} color="#FFFFFF" />
                      ) : (
                        <BrokenLinkIcon
                          width={20}
                          height={20}
                          color="#FFFFFF"
                        />
                      )
                    }
                    sx={{
                      backgroundColor: company.syncWithZconnect
                        ? palette.primary.main
                        : palette.error[200],
                      '&:hover': {
                        backgroundColor: company.syncWithZconnect
                          ? 'rgb(77, 111, 163)'
                          : palette.error[200],
                      },
                    }}
                  >
                    Hubspot
                  </StyledCompanyDetailsButton>
                ) : null}
                {company.hsCompanyLink ? (
                  <StyledCompanyDetailsButton
                    target="_blank"
                    href={company.qbCompanyLink}
                    variant="contained"
                    startIcon={
                      company.qbCompanyLink ? (
                        <QuickbooksIcon
                          width={20}
                          height={20}
                          color="#FFFFFF"
                        />
                      ) : (
                        <BrokenLinkIcon
                          width={20}
                          height={20}
                          color="#FFFFFF"
                        />
                      )
                    }
                    sx={{
                      backgroundColor: company.qbCompanyLink
                        ? palette.primary.main
                        : palette.error[200],
                      '&:hover': {
                        backgroundColor: company.qbCompanyLink
                          ? 'rgb(77, 111, 163)'
                          : palette.error[200],
                      },
                    }}
                  >
                    QuickBooks
                  </StyledCompanyDetailsButton>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel
          sx={{
            padding: '24px 8px',
            '-ms-overflow-style': 'none' /* Internet Explorer 10+ */,
            'scrollbar-width': 'none' /* Firefox */,
            overflow: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none' /* Safari and Chrome */,
            },
          }}
          value="contacts"
        >
          <CompanyContacts
            company_hs_id={hsId}
            users={users}
            onChangePassword={handleUserChangePassword}
            onDelete={handleDeleteUser}
            onUpdate={handleUpdate}
          />
        </TabPanel>
      </TabContext>

      <Modal
        padding24
        open={currencyDialog.show}
        maxWidth="sm"
        title="Choose currency for the company"
        subtitle={
          <>
            According to the currency selected this company will be integrated
            with correspondent Quickbooks account.
            <br />
            WARNING: This change can’t be undone!
          </>
        }
        onClose={() => setCurrencyDialog(currencyState)}
      >
        <>
          <ModalContent>
            <FormControl>
              <RadioGroup
                aria-labelledby="currency-radio-buttons-group-label"
                defaultValue={company.currency}
                name="radio-buttons-group"
                value={currencyDialog.value}
                onChange={handleChangeCurrency}
                sx={{ gap: '16px' }}
              >
                {paymentCompanyOptions.map(({ value, code, description }) => (
                  <FormControlLabel
                    value={value}
                    sx={{
                      fontFamily: 'Poppins, sans-serif',
                      span: {
                        py: 0,
                      },
                    }}
                    control={<Radio />}
                    label={<RadioLabel code={code} description={description} />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </ModalContent>
          <ModalActions $padding24>
            <ModalCloseButton
              variant="outlined"
              autoFocus
              onClick={() => setCurrencyDialog(currencyState)}
            >
              Cancel
            </ModalCloseButton>
            <ModalSubmitButton
              variant="contained"
              disabled={!currencyDialog.value}
              onClick={handleSubmitCurrency}
            >
              Select
            </ModalSubmitButton>
          </ModalActions>
        </>
      </Modal>

      <DeleteContactModal
        open={deleteUser.show}
        user={deleteUser.data as UserRow}
        onSubmit={showDeleteCaptchaDialog}
        onClose={handleReset}
      />

      <ChangePasswordModal
        open={userChangePassword.show}
        user={userChangePassword.data as UserRow}
        onChange={(value) => setPassword((prev) => ({ ...prev, value }))}
        value={password.value}
        error={password.isError}
        onClose={handleReset}
        onSubmit={showCaptchaDialog}
      />

      <PasswordChangedModal
        open={password.isComplete}
        user={userChangePassword.data as UserRow}
        password={password.value}
        onClose={() => setPassword((prev) => ({ ...prev, isComplete: false }))}
      />

      {showCaptcha && (
        <CaptchaModal
          onComplete={handleConfirm}
          onClose={() => setShowCaptcha(false)}
        />
      )}

      {showDeleteCaptcha && (
        <CaptchaModal
          onComplete={handleDeleteConfirm}
          onClose={() => setShowDeleteCaptcha(false)}
        />
      )}
    </Paper>
  );
};
