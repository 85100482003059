import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import { Typography, Box } from '@mui/material';
import { invoicesState, setTotalMeta } from 'store/invoices/invoicesSlice';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';
import { Invoice, Invoices } from './PastDueChart.styles';

const colors = ['#9EC287', '#EECF53', '#EF9F55', '#DD6769'];

type PastDueChartProps = { companyHsId?: string; currency?: string };

const PastDueChart = ({ companyHsId, currency }: PastDueChartProps) => {
  const dispatch = useBaoDispatch();
  const invoicesTotalMeta = useBaoSelector((state) => state.invoices.totalMeta);
  const [invoices, setInvoices] = useState([
    {
      count: 0,
      period: '',
      total: 0,
    },
  ]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/invoices-total-amounts`,
        {
          params: {
            company_hs_id: companyHsId,
            ...(currency ? { currency } : {}),
          },
        }
      )
      .then(({ data }) => {
        dispatch(
          setTotalMeta(
            mapKeys(data.meta, (v, k) =>
              camelCase(k)
            ) as invoicesState['totalMeta']
          )
        );
        setInvoices(data.items);
      });
  }, [companyHsId, dispatch, currency]);

  const invoicePercentage = (invoice: (typeof invoices)[0]) =>
    `${(invoice.total / invoicesTotalMeta.totalIncome) * 100}%`;

  return (
    <Invoices>
      {invoices.map((invoice, i) => (
        <Invoice
          key={invoice.period}
          $color={colors[i]}
          $width={invoicePercentage(invoice)}
        >
          <Box>
            {invoice.period === 'current'
              ? 'Current month outstanding'
              : `Past Due: ${invoice.period} days`}
          </Box>
          <Typography variant="h3" fontSize="1rem" color="#fff" my={1}>
            {currencyFormatter(Number(invoice.total), currency || 'USD')}
          </Typography>
          <Box>{invoice.count} invoices</Box>
        </Invoice>
      ))}
    </Invoices>
  );
};

export default PastDueChart;
