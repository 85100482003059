import React from 'react';
import styled from 'styled-components';
import {
  Autocomplete,
  InputAdornment,
  Select,
  Typography,
} from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

export const CustomAutocomplete = styled(({ ...otherProps }) => (
  <Autocomplete {...otherProps} />
))`
  width: 100%;
`;

export const CustomTypography = styled(({ ...props }) => (
  <Typography {...props} />
))`
  && {
    font-weight: 400;
    padding-bottom: 10px;
    line-height: 25px;
  }
`;

export const StyledInputAdornment = styled(({ ...props }) => (
  <InputAdornment {...props} />
))`
  && {
    p.MuiTypography-root {
      color: ${neutrals[400]};

      font-family: Poppins, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

export const StyledQuickbookSelect = styled(({ ...props }) => (
  <Select {...props} />
))`
  && {
    min-height: 40px;
    .MuiSelect-select {
      min-height: 0;
      font-size: 0.875rem;
      line-height: 18px;
      padding: 10px 14px;
    }
    &:hover {
      .MuiOutlinedInput-root:not(.Mui-disabled)
        .MuiOutlinedInput-notchedOutline {
        border-color: ${palette.primary.main} !important;
      }
    }
  }
`;
