import React from 'react';
import { Grid, Stack, Tooltip } from '@mui/material';
import Link from 'atoms/Link/Link';
import { StyledDetailsText } from 'molecules/Table/styles/Text.styles';
import {
  StyledDetailsContainer,
  StyledReportInfo,
} from 'pages/CloudOpsReportAdmin/CloudOpsReportAdminPage.styles';

import dayjs from 'dayjs';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';

import { Report } from 'pages/CloudOpsReportAdmin/types';
import { ReactComponent as InfoIcon } from 'assets/icons/new_info.svg';

const getInvoiceInfo = (report: Report) => {
  const currentDate = new Date();
  // Temp logic:
  if (report.year === 2023 && report.month < 9) return '';

  // https://zira.zstream.io/app/tasks/task/RESELL-2054
  if (
    report.month + 1 === currentDate.getMonth() + 1 &&
    report.project?.total_fee === 0
  ) {
    return (
      <Stack direction="row" gap="8px">
        <StyledReportInfo>Total fee is 0,00</StyledReportInfo>
        <Tooltip title="Invoice will not be generated by Zazmic-Connect because it has Total fee = 0.00">
          <span>
            <InfoIcon width={18} height={18} color="#FFB672" />
          </span>
        </Tooltip>
      </Stack>
    );
  }

  if (report.invoice_number) {
    return `#${report.invoice_number}`;
  }

  if (report.last_approval && !report.is_invoice_created) {
    return `Time approved on ${dayjs(new Date(report.last_approval)).format(
      'MMM DD, YYYY'
    )}`;
  }

  // https://zira.zstream.io/app/tasks/task/RESELL-1447
  if (
    report.month === currentDate.getMonth() + 1 &&
    !report.is_invoice_created
  ) {
    return '';
  }

  // https://zira.zstream.io/app/tasks/task/RESELL-1430
  if (
    (report.month < currentDate.getMonth() + 1 || currentDate.getDate() >= 6) &&
    !report.is_invoice_created &&
    report.current_consumption > 0 // https://zira.zstream.io/app/tasks/task/RESELL-1727
  ) {
    return 'Time not approved';
  }

  return '';
};

const getInvoiceColor = (report: Report) => {
  const currentDate = new Date();
  const defaultColor = neutrals[700];

  if (report.year === 2023 && report.month < 9) return defaultColor;

  if (report.invoice_number) {
    return defaultColor;
  }

  if (report.last_approval && !report.is_invoice_created) {
    return defaultColor;
  }

  // https://zira.zstream.io/app/tasks/task/RESELL-1447
  if (
    report.month === currentDate.getMonth() + 1 &&
    !report.is_invoice_created
  ) {
    return defaultColor;
  }

  // https://zira.zstream.io/app/tasks/task/RESELL-1430
  if (
    (report.month < currentDate.getMonth() + 1 || currentDate.getDate() >= 6) &&
    !report.is_invoice_created
  ) {
    return palette.error[700];
  }

  return defaultColor;
};

const renderRowDetails = (row: Report, link?: string) => (
  <Grid container spacing={0} sx={{ p: '8px 80px' }}>
    <Grid item xs>
      <StyledDetailsContainer $firstCol>
        {link && (
          <Link to={link} target="_blank" rel="noopener noreferrer">
            View Tasks in ZStream
          </Link>
        )}
      </StyledDetailsContainer>
    </Grid>
    <Grid item xs="auto">
      <StyledDetailsContainer>
        <StyledDetailsText>Monthly budget</StyledDetailsText>
        <StyledDetailsText>Current consumption</StyledDetailsText>
        <StyledDetailsText $divider>Over consumed</StyledDetailsText>
        <StyledDetailsText>Minimal monthly fee</StyledDetailsText>
        <StyledDetailsText>Over consumed hourly rate</StyledDetailsText>
        <StyledDetailsText $divider>Over budget fee</StyledDetailsText>
        <StyledDetailsText $bold>Total fee</StyledDetailsText>
      </StyledDetailsContainer>
    </Grid>
    <Grid item xs="auto" minWidth={120}>
      <StyledDetailsContainer>
        {/* Monthly budget */}
        <StyledDetailsText>
          {row.project.monthly_budget_in_hours || 0} hrs
        </StyledDetailsText>

        {/* Current consumption */}
        <StyledDetailsText>{row.current_consumption} hrs</StyledDetailsText>

        {/* Overconsumed */}
        <StyledDetailsText $divider>
          {row.credit_balance?.toFixed(2)} hrs
        </StyledDetailsText>

        {/* Minimal monthly fee */}
        <StyledDetailsText>
          {currencyFormatter(
            Number(row.project.minimum_monthly_budget),
            row.currency
          )}
        </StyledDetailsText>

        {/* Overconsumed Hourly rate< */}
        <StyledDetailsText>
          {currencyFormatter(
            Number(row.project.over_budget_hourly_rate),
            row.currency
          )}
        </StyledDetailsText>

        {/* Over budget fee */}
        <StyledDetailsText $divider>
          {currencyFormatter(Number(row.project.overBudget), row.currency)}
        </StyledDetailsText>

        {/* Total fee */}
        <StyledDetailsText $bold>
          {currencyFormatter(Number(row.project.total_fee), row.currency)}
        </StyledDetailsText>
      </StyledDetailsContainer>
    </Grid>
  </Grid>
);

export { getInvoiceInfo, getInvoiceColor, renderRowDetails };
