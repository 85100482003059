/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import { camelCase, mapKeys } from 'lodash-es';
import { Data } from 'organisms/InvoicesTable/types';
import { statusType } from 'utils/constants/invoiceStatus';
import { getBrowserConfig, httpLogger } from 'utils/helpers/logger';
import { UserState } from 'store/user/userSlice';

export type invoicesState = {
  totalAmount: number;
  allOpenSum: number;
  totalCount: number;
  page: number;
  isLoading: boolean;
  rows: Data;
  selected: readonly string[];
  statusFilter: statusType;
  totalMeta: {
    companiesCount: number;
    invoicesCount: number;
    totalDue: number;
    totalIncome: number;
  };
};

// Define the initial state using that type
const initialState: invoicesState = {
  totalAmount: 0,
  allOpenSum: 0,
  totalCount: 0,
  page: 0,
  isLoading: false,
  rows: [],
  selected: [],
  statusFilter: 'open',
  totalMeta: {
    companiesCount: 0,
    invoicesCount: 0,
    totalDue: 0,
    totalIncome: 0,
  },
};

export const storeInvoicesInfo = createAsyncThunk(
  'invoices/storeInvoicesInfo',
  async (
    {
      newPage,
      rowsPerPage,
      status,
      currency,
      sort,
      company,
      search,
      dateRange,
      period,
      customer,
    }: {
      newPage: number;
      rowsPerPage: number;
      status: statusType;
      currency?: string;
      sort: string;
      company: string | null | undefined;
      search: string | null | undefined;
      dateRange?: [null | Date, null | Date];
      period: number | null | undefined;
      customer: boolean | null | undefined;
    },
    { getState }
  ) => {
    const {
      user: { value: userValue },
    } = getState() as { user: ReduxState<UserState> };
    let response;
    let statusData = status === 'pending' ? 'payment_pending' : status;
    statusData = statusData === 'all' ? '' : statusData;
    statusData =
      statusData === 'customer' ? 'open,overdue,payment_pending' : statusData;

    if (period) {
      let filter = '1-30';
      if (period === 2) {
        filter = '31-60';
      }
      if (period === 3) {
        filter = '61%2B';
      }
      response = await axios.get(
        `${process.env.REACT_APP_BILLING_SERVICE}/dashboard/past-due-customer?period=${filter}`,
        {
          params: {
            status: customer ? 'all' : statusData,
            page: newPage + 1,
            per_page: rowsPerPage,
            limit: rowsPerPage,
            ...(company ? { company } : null),
            ...(search ? { search } : null),
          },
        }
      );
    } else {
      const params = {
        statuses: customer ? 'open,overdue,payment_pending' : statusData,
        page: newPage + 1,
        per_page: rowsPerPage,
        ...(currency && currency !== 'all' ? { currency } : null),
        ...(company ? { company } : null),
        ...(search ? { search } : null),
        ...(dateRange && dateRange[0] && dateRange[1]
          ? {
              date_from: dayjs(dayjs(dateRange[0])).format('YYYY-MM-DD'),
              date_to: dayjs(dayjs(dateRange[1])).format('YYYY-MM-DD'),
            }
          : {}),
      };
      httpLogger([
        getBrowserConfig(),
        `${userValue.email} accessing endpoint: GET ${
          process.env.REACT_APP_BILLING_SERVICE
        }/invoices/${sort}, params: ${JSON.stringify(params)}`,
      ]);
      response = await axios.get(
        `${process.env.REACT_APP_BILLING_SERVICE}/invoices?${sort}`,
        { params }
      );
      httpLogger([
        `GET ${process.env.REACT_APP_BILLING_SERVICE}/invoices?${sort}, ${userValue.email} received data: ${response.data?.meta?.total_count}`,
      ]);
    }

    const data = response.data.invoices.map((invoice: Data[0]) => {
      const camelCasedInvoice = mapKeys(invoice, (v, k) =>
        camelCase(k)
      ) as Data[0];
      const d = new Date(Date.parse(camelCasedInvoice.date));
      return {
        ...camelCasedInvoice,
        date: dayjs(d.toString()).format('MMM DD, YYYY'),
        dueDate: dayjs(camelCasedInvoice.dueDate).format('MMM DD, YYYY'),
      };
    });

    let count = 0;
    let sum = 0;
    if (customer) {
      count = data.length;
      data.forEach((row: { amountDue: number }) => {
        sum += Number(row.amountDue);
      });
    }

    const countResponse = response.data.meta.total_count
      ? response.data.meta.total_count
      : response.data.meta.invoices_count;

    return {
      allOpenSum: response.data.meta.total_due_amount,
      totalAmount: customer ? sum : response.data.meta.total_amount,
      totalCount: customer ? count : countResponse,
      page: newPage,
      rows: data,
      selected: [],
      search,
    };
  }
);

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSelected: (state, action: PayloadAction<string[]>) => {
      state.selected = action.payload;
    },
    setStatusFilter: (state, action: PayloadAction<statusType>) => {
      state.statusFilter = action.payload;
    },
    setTotalMeta: (
      state,
      action: PayloadAction<invoicesState['totalMeta']>
    ) => {
      state.totalMeta = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeInvoicesInfo.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(storeInvoicesInfo.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
    builder.addCase(storeInvoicesInfo.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
      isLoading: false,
    }));
  },
});

export const { setPage, setSelected, setStatusFilter, setTotalMeta } =
  invoicesSlice.actions;

export default invoicesSlice.reducer;
