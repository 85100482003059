const invoiceOptions = [
  { value: 'open', label: 'Paid vs Outstanding' },
  {
    value: 'old_companies',
    label: 'Companies vs Companies with at least one invoice in a month',
  },
  {
    value: 'new_companies',
    label:
      'New Companies vs New Companies with at least one invoice in a month',
  },
];

const invoiceTypeOptions = [
  { value: 'all', label: 'All types of invoices' },
  { value: 'gcp', label: 'GCP invoices' },
  { value: 'co', label: 'CloudOps invoices' },
];

const invoiceCurrencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'Euro' },
  { value: 'GBP', label: 'GBP' },
];

export { invoiceOptions, invoiceTypeOptions, invoiceCurrencyOptions };
