import styled from 'styled-components';
import { ListItemIcon, MenuItem, TextField, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const StyledSelect = styled(TextField)<{
  $open: boolean;
}>`
  && {
    width: 100%;
    .MuiSelect-root.MuiInput-root:hover {
      &:before,
      &:after {
        border-bottom: 1px solid ${palette.primary.main} !important;
      }
    }

    .MuiSelect-root {
      &:before,
      &:after {
        border-bottom: ${({ $open }) =>
          $open
            ? `1px solid ${palette.primary.main} !important`
            : `1px solid ${neutrals[200]} !important`};
      }
    }
    .MuiSelect-select {
      background-color: transparent !important;

      margin: 0 8px;
      color: ${neutrals[700]};

      font-family: Poppins, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .MuiSvgIcon-root {
      right: 8px;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    height: 40px;
    padding: 5px 8px !important;
    gap: 8px;
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 0 !important;
  }
`;

const StyledItemText = styled(Typography)`
  && {
    color: ${neutrals[700]};

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`;

export { StyledSelect, StyledMenuItem, StyledListItemIcon, StyledItemText };
