import styled from 'styled-components';
import { Stack } from '@mui/material';

export const StyledInvoiceTableDetails = styled(Stack)`
  && {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .MuiBox-root {
      display: flex;
      gap: 5px;
      .MuiTypography-body2 {
        font-family: 'Poppins', sans-serif;
        font-size: 0.675rem;
        font-weight: 400;
        line-height: 10px;
        text-align: right;
        color: #575d66;
      }

      .MuiTypography-body2:first-of-type {
        width: 100px;
      }
    }
  }
`;
