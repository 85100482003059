import React, { FC, ReactElement, useState, useRef } from 'react';
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import {
  StyledSelect,
  StyledItemText,
  StyledMenuItem,
} from 'molecules/Select/Select.styles';

const Select: FC<{
  name?: string;
  defaultValue?: string;
  options: {
    icon?: ReactElement;
    label: string;
    value: string;
  }[];
  onSelect: (value: string, name?: string) => void;
}> = ({ name, defaultValue, options, onSelect }) => {
  const anchorRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string | null>(defaultValue || null);
  const [open, setOpen] = useState(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (option: {
    icon?: ReactElement;
    label: string;
    value: string;
  }) => {
    setValue(option.value);
    onSelect(option.value, name);
    setOpen(false);
  };

  return (
    <div>
      <StyledSelect
        $open={open}
        select
        size="medium"
        value={value}
        ref={anchorRef}
        variant="standard"
        InputProps={{
          onMouseDown: () => {
            if (open) setOpen(false);
          },
        }}
        SelectProps={{
          open: false,
          onOpen: () => setOpen(true),
        }}
      >
        {options?.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        style={{
          zIndex: 1,
          top: '10px',
        }}
        role={undefined}
        popperOptions={{
          placement: 'bottom-start',
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                minWidth: anchorRef?.current && anchorRef?.current?.offsetWidth,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  sx={{ py: '4px' }}
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {options.map((option) => (
                    <StyledMenuItem
                      key={option.value}
                      onClick={() => handleItemClick(option)}
                    >
                      {option.icon && option.icon}
                      <StyledItemText>{option.label}</StyledItemText>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Select;
