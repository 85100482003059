import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IconButton,
  Tooltip,
  SelectChangeEvent,
  FormControl,
} from '@mui/material';
import {
  StyledSelect,
  StyledMenuItem,
  StyledLabel,
} from './StatusSelect.styles';
import { statusType, sortOptions } from 'utils/constants/invoiceStatus';
import { setPage, setSelected } from 'store/invoices/invoicesSlice';
import { useBaoDispatch } from 'utils/hooks/redux';
import { IconTextFieldWrapper } from 'molecules/IconTextField/IconTextField.styles';
import { palette } from 'utils/styles/variables';

import { ReactComponent as ByInvoiceIcon } from 'assets/icons/filterByInvoice.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface dataFormProps {
  onChange?: (arg0: statusType) => void;
  isComp?: boolean;
}

const InvoiceStatusSelect = ({ onChange, isComp }: dataFormProps) => {
  const dispatch = useBaoDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const status = searchParams.get('status') as statusType;

  const boxRef = useRef(null);

  const [open, setOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>('');

  const handleSortBy = (event: SelectChangeEvent<unknown>) => {
    dispatch(setSelected([]));
    dispatch(setPage(0));

    const newStatus = event.target.value as statusType;

    setStatusFilter(newStatus);
    if (onChange) {
      onChange(newStatus);
    }
  };

  const handleClickIcon = () => {
    setOpen((isOpen) => !isOpen);

    // if (onChange) onChange('all');
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !statusFilter));
    setStatusFilter('');

    if (onChange) onChange('all');
  };

  useEffect(() => {
    if (
      status &&
      status === 'all' &&
      location.state &&
      location.state.type &&
      location.state.type === 'all'
    ) {
      setStatusFilter('');
      if (onChange) {
        onChange(status);
      }
      navigate(`/invoices`, {
        state: { type: '' },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (isComp) {
      setStatusFilter('');
    }
  }, [dispatch, isComp]);

  return (
    <IconTextFieldWrapper ref={boxRef}>
      <Tooltip title="Filter by status">
        <IconButton
          aria-label="close"
          onClick={handleClickIcon}
          sx={{
            p: '0 !important',
            width: '20px !important',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          disabled={open}
        >
          <ByInvoiceIcon color={palette.primary.main} />
        </IconButton>
      </Tooltip>

      <FormControl
        variant="standard"
        sx={{
          width: 160,
          minWidth: 160,
          display: open || statusFilter ? 'flex' : 'none',
        }}
        size="small"
      >
        <StyledLabel
          id="filter-label"
          shrink={false}
          sx={{ transform: 'translate(45%, 17px) !important' }}
        >
          {statusFilter ? '' : 'Select status'}
        </StyledLabel>
        <StyledSelect
          open={open}
          value={statusFilter}
          id="filter-label"
          labelId="filter-label"
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={handleSortBy}
          MenuProps={{
            container: boxRef.current,
            className: 'invoice-status-select',
            hideBackdrop: true,
            onBlur: () => setOpen(false),
            sx: {
              position: 'unset',
              '.MuiPaper-root': {
                width: 160,
                textTransform: 'capitalize',
                minHeight: '185px',
              },
            },
          }}
          IconComponent={() =>
            statusFilter ? (
              <IconButton
                sx={{ padding: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  handleClick();
                }}
              >
                <CloseIcon color={palette.error.main} width={16} height={16} />
              </IconButton>
            ) : null
          }
        >
          {sortOptions.map(({ key, value }) => (
            <StyledMenuItem key={key} value={value}>
              {key}
            </StyledMenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </IconTextFieldWrapper>
  );
};

export default InvoiceStatusSelect;
