import React, { FC } from 'react';

import { IconButton, Grid, Stack, Container } from '@mui/material';
import {
  StyledGridItem,
  StyledInvoiceDetailsTitle,
  StyledTitle,
} from 'pages/InvoiceDetailsPage/InvoiceDetailsPage.styles';
import {
  MobileInvoicesTitle,
  MobileInvoiceSubTitle,
} from 'utils/styles/Typography.styles';

import { usd } from 'utils/helpers/currencyFormatter';

import { ReactComponent as ArrowIcon } from 'assets/icons/arrow-left.svg';
import { Report } from 'pages/CloudOpsReport/types';
import { months } from 'utils/constants/common';

export const MobileCloudOpsDetail: FC<{
  companyName?: string;
  data: Report;
  link: string;
  onBack: () => void;
}> = ({ companyName, link, data, onBack }) => (
  <Container sx={{ minHeight: 'calc(100vh - 215px)', padding: 0 }}>
    <StyledInvoiceDetailsTitle sx={{ padding: '0 16px 24px !important' }}>
      <StyledTitle direction="row">
        <IconButton onClick={onBack} sx={{ padding: '0' }}>
          <ArrowIcon />
          Back
        </IconButton>
        <MobileInvoicesTitle variant="h2" sx={{ fontSize: '22px' }}>
          {`${months[data.month - 1]}, ${data.year}`}
        </MobileInvoicesTitle>
      </StyledTitle>

      <Grid container rowSpacing={1}>
        <StyledGridItem item xs={12}>
          <MobileInvoiceSubTitle variant="h5">
            {companyName || ''}
          </MobileInvoiceSubTitle>
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Monthly Budget
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {data.project.monthly_budget_in_hours || 0} hrs
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Current Consumption
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {data.current_consumption} hrs
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Credit Balance
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {data.project.credit_balance} hrs
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Minimal Monthly Fee
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {usd.format(Number(data.project.minimum_monthly_budget))}
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Hourly Rate
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {usd.format(Number(data.project.over_budget_hourly_rate))}
        </StyledGridItem>

        <StyledGridItem item xs={8}>
          Over Budget Fee
        </StyledGridItem>
        <StyledGridItem item xs={4}>
          {usd.format(Number(data.project.overBudget))}
        </StyledGridItem>

        <StyledGridItem
          item
          xs={8}
          style={{ fontWeight: 600, fontSize: '0.875rem' }}
        >
          Total Fee
        </StyledGridItem>
        <StyledGridItem
          item
          xs={4}
          style={{ fontWeight: 600, fontSize: '0.875rem' }}
        >
          {usd.format(Number(data.project.total_fee))}
        </StyledGridItem>
      </Grid>
    </StyledInvoiceDetailsTitle>

    <Stack
      direction="row"
      justifyContent="flex-end"
      sx={{ padding: '24px 16px' }}
    >
      {link.length > 0 ? (
        <a
          style={{
            color: '#6F9FE9',
            fontWeight: 500,
            fontSize: '0.75rem',
            textDecorationLine: 'underline',
          }}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Tasks in ZStream
        </a>
      ) : (
        <></>
      )}
    </Stack>
  </Container>
);
