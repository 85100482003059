import React, { FC, useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { ADMIN, SUPER_ADMIN } from 'utils/constants/roles';
import { useBaoDispatch, useBaoSelector } from 'utils/hooks/redux';
import { StyledTableWrapper } from './CloudOpsReportAdminPage.styles';
import { months } from 'utils/constants/common';
import { Report } from './types';
import { Company } from 'pages/CloudOpsReport/types';
import { getCloudOpsReports } from 'store/cloudOps/cloudOpsSlice';
import {
  getInvoiceColor,
  getInvoiceInfo,
  renderRowDetails,
} from 'pages/CloudOpsReportAdmin/common';
import { StyledCellText } from 'molecules/Table/styles/Text.styles';
import Table from 'molecules/Table';
import { StyledTableContainer } from 'molecules/Table/styles';
import PaginationActions from 'molecules/Table/TablePagination/Actions';
import { StyledTablePagination } from 'molecules/Table/styles/Pagination.styles';

const CloudOpsReportAdminTable: FC<{ company: Company }> = ({ company }) => {
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [loader, setLoader] = useState(false);
  const [link, setLink] = useState<string>('');

  const [activeReport, setActiveReport] = useState<number>();

  const dispatch = useBaoDispatch();
  const {
    user: { value: user },
    cloudOps,
  } = useBaoSelector((state) => state);
  const hsId = String(company.companyHsId);
  const isAnyAdmin = user.userType === ADMIN || user.userType === SUPER_ADMIN;
  const isSuperAdmin = user.userType === SUPER_ADMIN;

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage);
    fetchPageData(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (option: number) => {
    setRowsPerPage(option);
    fetchPageData(currentPage, option);
  };

  const columns = useMemo(() => {
    const returnValue = [
      {
        key: 'month',
        label: 'Month',
        sortable: false,
        width: '30%',
        render: ({ month, year }: Report) => (
          <StyledCellText $textType="bold">
            {months[month - 1]}, {year}
          </StyledCellText>
        ),
      },
      {
        key: 'project',
        label: 'Project Name',
        sortable: false,
        width: isAnyAdmin ? '45%' : '70%',
        render: ({ project }: Report) => (
          <StyledCellText $textType="default">{project.name}</StyledCellText>
        ),
      },
    ];

    if (isAnyAdmin) {
      returnValue.push({
        key: 'invoice_number',
        label: 'Invoice #',
        sortable: false,
        width: '25%',
        render: (report: Report) => (
          <StyledCellText $color={getInvoiceColor(report)}>
            {getInvoiceInfo(report)}
          </StyledCellText>
        ),
      });
    }

    return returnValue;
  }, [isAnyAdmin]);

  const rowDetailsRenderer = useCallback(
    (row: Report) => renderRowDetails(row, link),
    [link]
  );

  const handleRowClick = useCallback(
    (event, row) => {
      if (window.getSelection()?.type === 'Range') return;

      if (activeReport === row.id) {
        setActiveReport(0);
        setLink('');
      } else {
        if (row.tasks_link) {
          axios
            .get(
              `${process.env.REACT_APP_USER_SERVICE}/company/${row.project.company_hs_id}`
            )
            .then((d) => {
              if (d.data.disable_access_to_zstream === false || isSuperAdmin) {
                setLink(row.tasks_link);
              }
            });
        }
        setActiveReport(row.id);
      }
    },
    [activeReport]
  );

  async function fetchPageData(page: number, limit: number) {
    setLoader(true);
    await dispatch(
      getCloudOpsReports({
        company_hs_id: hsId,
        limit,
        page,
      })
    );
    setLoader(false);
  }

  return (
    <StyledTableWrapper>
      <StyledTableContainer sx={{ minWidth: 1000 }}>
        <Table
          idKey="id"
          loading={loader}
          collapsible
          collapsedRow={activeReport}
          columns={columns}
          data={cloudOps?.projects_reports || []}
          onRowClick={handleRowClick}
          rowDetailsRenderer={rowDetailsRenderer}
        />
        <StyledTablePagination
          labelDisplayedRows={() => null}
          rowsPerPageOptions={[]}
          count={cloudOps?.count || 0}
          rowsPerPage={rowsPerPage}
          page={currentPage}
          onPageChange={handleChangePage}
          ActionsComponent={(props) => (
            <PaginationActions
              {...props}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[20, 50, 100, 200]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        />
      </StyledTableContainer>
    </StyledTableWrapper>
  );
};

export default CloudOpsReportAdminTable;
