import styled from 'styled-components';
import { Box, TextField, Typography } from '@mui/material';
import { neutrals } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const ListItem = styled(Box)`
  && {
    height: 21px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ListItemText = styled(Typography)<{
  $cursor?: boolean;
}>`
  && {
    overflow: hidden;
    color: ${neutrals[700]};
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: ${({ $cursor }) => ($cursor ? 'pointer' : 'default')};

    /* New/TableTextDefault */
    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */

    &:hover {
      color: ${({ $cursor }) =>
        $cursor ? palette.primary.main : neutrals[700]};
    }
  }
`;

const StyledTextField = styled(TextField)`
  && {
    .MuiSelect-select.MuiSelect-outlined {
      color: ${neutrals[700]};

      font-family: Poppins, sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;

export { ListItem, ListItemText, StyledTextField };
