import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { camelCase, mapKeys } from 'lodash-es';
import {
  Card,
  CardHeader,
  CardContent,
  Stack,
  IconButton,
  Typography,
  Box,
  Alert,
} from '@mui/material';
import CardAvatar from 'atoms/CardAvatar/CardAvatar';
import InvoicesTable from 'organisms/InvoicesTable/InvoicesTable';
import { useBaoSelector } from 'utils/hooks/redux';
import { SUPER_ADMIN } from 'utils/constants/roles';
import { useNavigate } from 'react-router-dom';
import {
  HeaderTitleWrapper,
  InvoicesCircle,
  StyledInvoicePaymentItem,
  TableWrapper,
} from './OpenInvoices.styles';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { currencyIcon } from 'utils/constants/invoiceStatus';
import { currencyFormatter } from 'utils/helpers/currencyFormatter';

const OpenInvoices = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [height, setHeight] = useState<boolean>(false);
  const [isPayed, setPayed] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<PMethod>();
  const [currency, setCurrency] = useState<
    keyof typeof currencyIcon | undefined
  >();

  const { value: user } = useBaoSelector((state) => state.user);
  const { currentCompany } = useBaoSelector((state) => state.common);
  const invoices = useBaoSelector((state) => state.invoices);

  useEffect(() => {
    if (!currentCompany) return;

    axios
      .get(
        `${process.env.REACT_APP_USER_SERVICE}/company/${currentCompany.company_hs_id}`
      )
      .then((response) => {
        const { data } = response;
        setCurrency(data.currency as keyof typeof currencyIcon);
      });
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/payment-settings`,
        { params: { company_hs_id: currentCompany.company_hs_id } }
      )
      .then(({ data }) => setPayed(data.is_auto_payment));
    axios
      .get(
        `${process.env.REACT_APP_BILLING_SERVICE}/payment/main-payment-method`,
        { params: { company_hs_id: currentCompany.company_hs_id } }
      )
      .then((res) => {
        const result = mapKeys(res.data, (v, k) => camelCase(k)) as PMethod;
        setPaymentMethod(result);
      });
  }, []);

  function getPaymentMethodName(method?: PMethod) {
    if (method?.paymentMethod === 'card') return method.creditCardInfo.brand;
    if (method?.paymentMethod === 'ach_debit')
      return method.banckAccountInfo.bank_name;
    if (method?.paymentMethod === 'acss_debit')
      return method.banckAccountInfo.bank_name;
    if (method?.paymentMethod === 'sepa_debit') return 'SEPA';
    return '';
  }
  function getPaymentMethodLast4(method?: PMethod) {
    if (method?.paymentMethod === 'card') return method.creditCardInfo.last4;
    if (method?.paymentMethod === 'ach_debit')
      return method.banckAccountInfo.last4;
    if (method?.paymentMethod === 'acss_debit')
      return method.banckAccountInfo.last4;
    if (method?.paymentMethod === 'sepa_debit')
      return method.banckAccountInfo.last4;
    return '';
  }

  return (
    <Card>
      {error && (
        <Alert severity="error" sx={{ mb: 1 }}>
          {error}
        </Alert>
      )}
      <CardHeader
        title={
          <HeaderTitleWrapper>
            <Box display="flex" alignItems="center">
              <Box component="span" mr={1}>
                Outstanding Invoices
              </Box>
              <InvoicesCircle $size={24}>{invoices.totalCount}</InvoicesCircle>
            </Box>
            <Stack>
              <StyledInvoicePaymentItem>
                <Typography variant="h3">Total Due:</Typography>
                <Typography variant="h3">
                  {currencyFormatter(invoices.allOpenSum, currency || 'USD')}
                </Typography>
              </StyledInvoicePaymentItem>
              <hr style={{ border: '.5px solid #E8E9EB', width: '100%' }} />
              <StyledInvoicePaymentItem>
                <Typography variant="h3">Auto Payment:</Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: '500 !important' }}
                    color={`${isPayed ? 'green' : 'red'} !important`}
                  >
                    {isPayed ? 'Enabled' : 'Not Enabled'}
                  </Typography>
                  {isPayed ? (
                    <Typography color="#484E56">
                      , {getPaymentMethodName(paymentMethod)} ****{' '}
                      {getPaymentMethodLast4(paymentMethod)}
                    </Typography>
                  ) : (
                    ''
                  )}
                </Box>
                <IconButton
                  onClick={() => navigate('/payment')}
                  sx={{ padding: 0, marginLeft: '30px' }}
                >
                  <SettingsIcon />
                </IconButton>
              </StyledInvoicePaymentItem>
            </Stack>
          </HeaderTitleWrapper>
        }
        avatar={<CardAvatar />}
      />
      <CardContent sx={{ p: '0 !important' }}>
        <TableWrapper height={height}>
          <InvoicesTable
            customer
            setHeight={(e) => setHeight(e)}
            status="open"
            onError={setError}
            companyColumn={user.userType === SUPER_ADMIN}
            company={
              currentCompany?.company_hs_id
                ? String(currentCompany.company_hs_id)
                : null
            }
          />
        </TableWrapper>
      </CardContent>
    </Card>
  );
};

export default OpenInvoices;
