import React, { useEffect, useState } from 'react';

import { Month, Calendar, Arrow, Row, Flex, YearText } from './styles';

import { monthsNumber } from './constants';
import { MonthRangePickerProps } from './types';

import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

export const MonthRangePicker: React.FC<MonthRangePickerProps> = ({
  value,
  onRangeSelect,
  initialYear = new Date().getFullYear(),
  locale = 'en-US',
  calendarClassName,
  headerClassName,
  monthClassName,
}) => {
  const [startMonth, setStartMonth] = useState<Date | null>(value[0]);
  const [endMonth, setEndMonth] = useState<Date | null>(value[1]);
  const [yearOffset, setYearOffset] = useState<number>(0);

  const today = new Date();
  const currentYear = today.getFullYear();

  const isLastYear = currentYear + yearOffset + 1 > currentYear;

  const isMonthSelected = (month: Date) => {
    if (!startMonth || !endMonth) {
      return false;
    }
    const startYearMonth =
      startMonth.getFullYear() * 12 + startMonth.getMonth();
    const endYearMonth = endMonth.getFullYear() * 12 + endMonth.getMonth();
    const currentYearMonth = month.getFullYear() * 12 + month.getMonth();

    return (
      currentYearMonth >= startYearMonth && currentYearMonth <= endYearMonth
    );
  };

  const isMonthStart = (month: Date) =>
    month.getTime() === (startMonth?.getTime() || 0);

  const isMonthEnd = (month: Date) => {
    if (!endMonth) {
      return false;
    }

    const endYearMonth = endMonth.getFullYear() * 12 + endMonth.getMonth();
    const currentYearMonth = month.getFullYear() * 12 + month.getMonth();

    return currentYearMonth === endYearMonth;
  };

  const handleMonthClick = (month: Date) => {
    if (!startMonth) {
      setStartMonth(month);
      setEndMonth(month);
    } else if (month < startMonth) {
      setStartMonth(month);
      setEndMonth(startMonth);
    } else {
      setEndMonth(month);
    }
  };

  const renderMonth = (year: number, month: number) => {
    const monthStart = new Date(year, month, 1);
    const isSelected = isMonthSelected(monthStart);
    const isStart = isMonthStart(monthStart);
    const isEnd = isMonthEnd(monthStart);
    const isDisabled = monthStart > today;

    const getVariants = () => {
      if (isStart && isEnd) return 'startEnd'; // is same month
      if (isStart) return 'start';
      if (isEnd) return 'end';
      if (isSelected) return 'selected';
      return null;
    };

    return (
      <Month
        key={month}
        className={`${monthClassName}_${getVariants()}`}
        sx={{ justifyContent: 'center' }}
        // eslint-disable-next-line
        // @ts-ignore
        disabled={isDisabled}
        variant={getVariants() as string}
        onClick={isDisabled ? undefined : () => handleMonthClick(monthStart)}
      >
        {new Intl.DateTimeFormat(locale, { month: 'short' }).format(monthStart)}
      </Month>
    );
  };

  const renderYear = (year: number) => (
    <Row
      sx={{ flexWrap: 'wrap', justifyContent: 'center' }}
      rowGap="16px"
      pt="16px"
    >
      {monthsNumber.map((month) => renderMonth(year, month))}
    </Row>
  );

  const handlePrevYear = () => {
    const offset = yearOffset - 1;

    if (today.getFullYear() + offset < initialYear) {
      return;
    }

    setYearOffset(offset);
  };

  const handleNextYear = () => {
    if (!isLastYear) {
      const offset = yearOffset + 1;

      setYearOffset(offset);
    }
  };

  useEffect(() => {
    if (startMonth && endMonth) {
      const getInitialYear = startMonth.getFullYear();
      const getFinalYear = endMonth.getFullYear();
      const initialMonth = startMonth.getMonth() + 1;
      const finalMonth = endMonth.getMonth() + 1;

      onRangeSelect({
        startMonth: initialMonth,
        startYear: getInitialYear,
        endMonth: finalMonth,
        endYear: getFinalYear,
      });
    }
  }, [startMonth, endMonth, onRangeSelect]);

  return (
    <Calendar
      sx={{ boxShadow: 1 }}
      borderRadius="small"
      className={calendarClassName}
    >
      <Row
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className={headerClassName}
      >
        <Arrow onClick={handlePrevYear}>
          <ChevronLeft />
        </Arrow>
        <Flex>
          <YearText>{today.getFullYear() + yearOffset}</YearText>
        </Flex>
        <Arrow onClick={handleNextYear}>
          <ChevronRight />
        </Arrow>
      </Row>
      {renderYear(today.getFullYear() + yearOffset)}
    </Calendar>
  );
};
