import styled from 'styled-components';
import { Stack, CardHeader, Box } from '@mui/material';

export const StyledCardHeader = styled(CardHeader)`
  padding: 12px;
`;

export const StyledMonthDetails = styled(Stack)<{
  $largeLabel?: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    gap: 10px;

    position: absolute;
    top: 20px;
    right: 30px;

    .MuiBox-root {
      display: flex;
      gap: 5px;
      .MuiTypography-body2 {
        font-family: 'Poppins', sans-serif;
        font-size: 0.675rem;
        font-weight: 400;
        line-height: 10px;
        text-align: right;
        color: #575d66;
      }

      .MuiTypography-body2:first-of-type {
        width: ${({ $largeLabel }) => ($largeLabel ? '200px' : '100px')};
      }
      .MuiTypography-body2:last-of-type {
        flex: 1;
      }
    }
  }
`;

export const StyledFilterContainer = styled(Stack)`
  && {
    margin: 8px 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
    gap: 24px;

    div:first-of-type {
      flex: 1;
    }

    div:nth-of-type(2) {
      width: 200px;
    }

    div:nth-of-type(3) {
      width: 180px;
    }
  }
`;

export const StyledHorizontalBar = styled(Box)`
  && {
    position: relative;
    height: 72px;
    > div {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
    }
  }
`;
