export const neutrals: Partial<Color> = {
  200: '#E1E6ED',
  300: '#CAD0D9',
  400: '#A1A8B2',
  500: '#7B828C',
  600: '#575D66',
  700: '#3E444D',
  800: '#262B33',
  900: '#0E111A',
  A100: '#000000',
};

export const reds: Partial<Color> = {
  700: '#F34336',
};

export const light: Partial<Color> = {
  100: '#F6FAFF', // --Light-DDHovered
};
