import styled from 'styled-components';
import { Stack } from '@mui/material';
import Circle from 'utils/styles/Circle.styles';

interface Props {
  height: boolean;
}

export const HeaderTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableWrapper = styled.div<Props>`
  .MuiTableContainer-root {
    max-height: ${(p) => (p.height ? '985px' : '675px')};
  }
`;

export const InvoicesCircle = styled(Circle)`
  background-color: #f5baba;
  color: #ffffff;
  font-size: 0.75rem;
`;

export const StyledInvoicePaymentItem = styled(Stack)`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    > .MuiTypography-root:first-of-type {
      width: 100px;
      color: var(--Greys-Gr_Mid45, #676c73);
      text-align: right;

      font-family: 'Poppins';
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 133.333% */
      text-transform: capitalize;
    }
    > .MuiTypography-root:nth-of-type(2) {
      color: #484e56;

      font-family: 'Poppins';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      text-transform: capitalize;
    }
    .MuiBox-root > .MuiTypography-root {
      color: #484e56;

      font-family: 'Poppins';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      text-transform: capitalize;
    }
  }
`;
