import React, { useRef, useState } from 'react';

import {
  IconButton,
  Tooltip,
  SelectChangeEvent,
  FormControl,
  Box,
} from '@mui/material';
import {
  StyledSelect,
  StyledMenuItem,
  StyledLabel,
} from './StatusSelect.styles';
import { statusType } from 'utils/constants/invoiceStatus';
import { IconTextFieldWrapper } from 'molecules/IconTextField/IconTextField.styles';
import { palette } from 'utils/styles/variables';

import { ReactComponent as CurrencyIcon } from 'assets/icons/currency.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface dataFormProps {
  label?: string;
  onChange?: (arg0: string) => void;
  shouldNoCurrencyHidden?: boolean;
}

const currencyOptions = [
  { key: 'USD', value: 'USD' },
  { key: 'EUR', value: 'EUR' },
  { key: 'GBP', value: 'GBP' },
  { key: 'Not set', value: 'no_currency' },
];

const CurrencyStatusSelect = ({
  shouldNoCurrencyHidden,
  label,
  onChange,
}: dataFormProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<string>('');

  const boxRef = useRef(null);

  const handleSortBy = (event: SelectChangeEvent<unknown>) => {
    const newStatus = event.target.value as statusType;

    setStatusFilter(newStatus);
    if (onChange) {
      onChange(newStatus);
    }
  };

  const handleClickIcon = () => {
    setTimeout(() => {
      setOpen((isOpen) => !isOpen);
    }, 100);

    // if (onChange) onChange('all');
  };

  const handleClick = () => {
    setOpen((isOpen) => (isOpen ? false : !statusFilter));
    setStatusFilter('');

    if (onChange) onChange('all');
  };

  const filteredCurrencyOptions = shouldNoCurrencyHidden
    ? currencyOptions.filter((option) => option.value !== 'no_currency')
    : currencyOptions;

  return (
    <Box sx={{ position: 'relative' }}>
      <IconTextFieldWrapper>
        <Tooltip title={label || 'Filter by currency'}>
          <IconButton
            aria-label="close"
            // onClick={handleClickIcon}
            onMouseDown={handleClickIcon}
            sx={{
              p: '0 !important',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            disabled={open}
          >
            <CurrencyIcon width={24} height={24} color={palette.primary.main} />
          </IconButton>
        </Tooltip>

        <FormControl
          variant="standard"
          sx={{
            minWidth: 160,
            display: open || statusFilter ? 'flex' : 'none',
          }}
          size="small"
        >
          <StyledLabel
            id="filter-label"
            shrink={false}
            sx={{ transform: 'translate(35%, 17px) !important' }}
          >
            {statusFilter ? '' : 'Select currency'}
          </StyledLabel>
          <StyledSelect
            open={open}
            value={statusFilter}
            id="filter-label"
            labelId="filter-label"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onChange={handleSortBy}
            MenuProps={{
              container: boxRef.current,
              className: 'currency-status-select',
              hideBackdrop: true,
              onBlur: () => {
                setTimeout(() => setOpen(false), 150);
              },
              sx: {
                position: 'unset',
                '.MuiPaper-root': {
                  minWidth: '160px !important',
                  width: 160,
                },
              },
            }}
            IconComponent={() =>
              statusFilter ? (
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleClick();
                  }}
                >
                  <CloseIcon
                    color={palette.error.main}
                    width={16}
                    height={16}
                  />
                </IconButton>
              ) : null
            }
          >
            {filteredCurrencyOptions.map(({ key, value }) => (
              <StyledMenuItem key={key} value={value}>
                {key}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </IconTextFieldWrapper>
      {/* Container for menu paper */}
      <Box
        sx={{
          display: open ? 'block' : 'none',
          width: '180px',
          height: '160px',
          position: 'absolute',
          top: '26px',
          right: '-10px',
        }}
      >
        <Box
          ref={boxRef}
          sx={{
            zIndex: 10,
            position: 'relative',
            width: '100%',
            height: '100%',
            '.MuiPaper-root': {
              top: '0 !important',
              left: `10px !important`,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default CurrencyStatusSelect;
