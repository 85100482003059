import React, { ChangeEvent, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material';
import { ITablePaginationActionsProps } from 'molecules/Table/types';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  StyledItemText,
  StyledMenuItem,
} from 'molecules/ButtonMenu/ButtonMenu.styles';
import {
  StyledLabelPerPage,
  StyledSelectPerPage,
  StyledStackPerPage,
} from 'molecules/Table/styles/Pagination.styles';

const TablePaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
  labelRowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
}: ITablePaginationActionsProps) => {
  const theme = useTheme();

  const anchorRef = useRef<HTMLSelectElement>(null);

  const [open, setOpen] = useState(false);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleItemClick = (option: number) => {
    if (onRowsPerPageChange) onRowsPerPageChange(option);
    setOpen(false);
  };

  return (
    <Stack
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <StyledStackPerPage>
        <StyledLabelPerPage>{labelRowsPerPage}</StyledLabelPerPage>
        <Box ref={anchorRef} sx={{ height: '21px' }}>
          <StyledSelectPerPage
            select
            size="small"
            value={rowsPerPage}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleItemClick(Number(e.target.value))
            }
            SelectProps={{
              open: false,
              onOpen: () => setOpen(true),
            }}
          >
            {rowsPerPageOptions?.map((option) => (
              <StyledMenuItem key={option} value={option}>
                {option}
              </StyledMenuItem>
            ))}
          </StyledSelectPerPage>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            style={{
              zIndex: 1,
              top: '10px',
            }}
            role={undefined}
            popperOptions={{
              placement: 'bottom-end',
            }}
            transition
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      sx={{ py: '4px' }}
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                    >
                      {rowsPerPageOptions?.map((option) => (
                        <StyledMenuItem
                          key={option}
                          onClick={() => handleItemClick(option)}
                        >
                          <StyledItemText>{option}</StyledItemText>
                        </StyledMenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </StyledStackPerPage>

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowLeftIcon />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeftIcon />
          ) : (
            <KeyboardArrowRightIcon />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    </Stack>
  );
};

export default TablePaginationActions;
