export const paymentCompanyOptions = [
  {
    value: 'USD',
    code: 'USD',
    description: 'Zazmic USA Quickbooks account',
  },
  {
    value: 'EUR',
    code: 'Euro',
    description: 'Zazmic Ireland Quickbooks account',
  },
  {
    value: 'GBP',
    code: 'GBP',
    description: 'Zazmic Ireland Quickbooks account',
  },
];

export const paymentCompanyOptions1 = [
  {
    value: 'USD',
    code: 'usa-USD',
    // description: 'Zazmic USA Quickbooks account',
  },
];

export const paymentCompanyOptions2 = [
  {
    value: 'EUR',
    code: 'irl-EUR',
    // description: 'Zazmic Ireland Quickbooks account',
  },
  {
    value: 'GBP',
    code: 'irl-GBP',
    // description: 'Zazmic Ireland Quickbooks account',
  },
  // {
  //   value: 'USD',
  //   code: 'irl-USD',
  //   // description: 'Zazmic USA Quickbooks account',
  // },
];
